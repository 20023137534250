import React, { useState } from "react";
import { Row, Col, Card, Switch, Typography, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import "./AdminSettings.css";
const SettingsPage = (userRole, onLogout) => {
  const [editorUsername, setEditorUsername] = useState(""); // eslint-disable-line no-unused-vars
  const [editorPassword, setEditorPassword] = useState(""); // eslint-disable-line no-unused-vars
  const history = useNavigate(); // eslint-disable-line no-unused-vars

  // eslint-disable-next-line no-unused-vars
  const handleEditorUsername = (e) => {
    if (userRole !== "admin") {
      Modal.error({
        content: "You do not have permission to change the username",
      });
      return;
    }
    setEditorUsername(e.target.value);
  };

  // eslint-disable-next-line no-unused-vars
  const handleEditorPassword = (e) => {
    if (userRole !== "admin") {
      Modal.error({
        content: "You do not have permission to change the password",
      });
      return;
    }

    setEditorPassword(e.target.value);
  };

  const handleLogout = () => {
    Modal.confirm({
      className: "myCustomModal",
      title: "Are you sure you want to log out?",
      icon: <ExclamationCircleOutlined />,
      content: "You will be redirected to the home page.",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        window.location.href = "https://www.mogzit.com"; // Redirect to home page
      },
    });
  };
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [pushNotifications, setPushNotifications] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [themeColor, setThemeColor] = useState("#000000"); // eslint-disable-line no-unused-vars
  const [fontSize, setFontSize] = useState(16);// eslint-disable-line no-unused-vars

  const handleEmailNotifications = (checked) => {
    setEmailNotifications(checked);
  };

  const handlePushNotifications = (checked) => {
    setPushNotifications(checked);
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleChangePassword = () => {
    if (userRole !== "admin") {
      Modal.error({
        content: "You do not have permission to change the password",
      });
      return;
    }

    if (newPassword === confirmPassword && newPassword !== "") {
      // Code to change the editor's password
      console.log("Password changed successfully");
      setEditorPassword(newPassword);
    } else {
      console.log("Passwords do not match");
    }
  };

  const handleDeleteAccount = () => {
    // Code to delete account
    console.log("Account deleted successfully");
  };

  // eslint-disable-next-line no-unused-vars
  const handleThemeColor = (e) => {
    setThemeColor(e.target.value);
  };

  // eslint-disable-next-line no-unused-vars
  const handleFontSize = (e) => {
    setFontSize(e.target.value);
  };

  return (
    <div className="container mx-auto p-4">
      <Typography.Title
        level={1}
        style={{ color: "#CE257A", fontFamily: "Sora" }}
      >
        Setting
      </Typography.Title>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12} lg={8}>
          <Card
            title="Notifications"
            className="h-full sora"
            style={{ color: "#CE257A" }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <h3
                  className="text-lg font-semibold mb-4  sora"
                  style={{ color: "#CE257A" }}
                >
                  Email Notifications
                </h3>
                <p className="sora">Enable email notifications</p>
                <Switch
                  defaultChecked={emailNotifications}
                  onChange={handleEmailNotifications}
                  style={{ color: "#CE257A" }}
                />
              </Col>
              <Col span={24}>
                <h3
                  className="text-lg font-semibold mb-4 sora"
                  style={{ color: "#CE257A" }}
                >
                  Push Notifications
                </h3>
                <p>Enable push notifications for real-time updates</p>
                <Switch
                  defaultChecked={pushNotifications}
                  onChange={handlePushNotifications}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Card title="Account" className="h-full sora">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <h3
                  className="text-lg font-semibold mb-4 sora"
                  style={{ color: "#CE257A" }}
                >
                  Change Password
                </h3>
                <input
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={handleNewPassword}
                  className="input sora"
                  style={{
                    color: "#CE257A",
                    backgroundColor: "#ffffff",
                    marginBottom: 10,
                  }}
                />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={handleConfirmPassword}
                  className="input mt-2  sora"
                  style={{
                    color: "#CE257A",
                    backgroundColor: "#ffffff",
                  }}
                />
                <button
                  className="btn bg-red-500 text-white  sora"
                  style={{
                    backgroundColor: "#CE257A",
                    color: "#ffffff",
                    marginTop: 10,
                  }}
                  onClick={handleChangePassword}
                >
                  Change
                </button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Card
            title="Account Managment"
            className="h-full sora"
            style={{ color: "#CE257A" }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <h3
                  className="text-lg font-semibold mb-4 sora"
                  style={{ color: "#CE257A" }}
                >
                  Admin
                </h3>
                <p>Choose your options</p>
              </Col>
              <Col span={24}>
                <h3
                  className="text-lg font-semibold mb-4 sora"
                  style={{ color: "#CE257A" }}
                >
                  Delete Account
                </h3>
                <button
                  className="btn bg-red-500 text-white sora"
                  style={{ backgroundColor: "#CE257A", color: "#ffffff" }}
                  onClick={handleDeleteAccount}
                >
                  Delete
                </button>
              </Col>
              <Col span={24}>
                <h3
                  className="text-lg font-semibold mb-4 sora"
                  style={{ color: "#CE257A" }}
                >
                  Log Out
                </h3>
                <button
                  className="btn bg-red-500 text-white sora"
                  style={{ backgroundColor: "#CE257A", color: "#ffffff" }}
                  onClick={handleLogout}
                >
                  log out
                </button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SettingsPage;
