import {
  UserAddOutlined,
  UserOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  BookOutlined,
} from "@ant-design/icons";

import {
  Card,
  Space,
  Statistic,
  Alert,
  Spin,
  Row,
} from "antd";
import React, { useEffect, useState } from "react";
import { BookingAPI } from "../../../apis/bookingAPI";
//import {filterBookingsByStatus} from "./bookingHelpers";
import { filterBookingsByStatus } from "../AdminManageBookings/bookingHelpers";
import { ServiceProviderAPI } from "../../../apis/serviceProviderAPI";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "../ReportDashboard/ReportDashboard.css";
import { Bar } from "react-chartjs-2";
import Heatmap from "./Heatmap"; // eslint-disable-line no-unused-vars
const ReportDashboard = () => {
  const [timePeriod, setTimePeriod] = useState("weekly");
  // counting booking weekly, quarterly and all time
  const [newBookingsCountWeek, setNewBookingsCountWeek] = useState(0);
  const [completedBookingsCountWeek, setCompletedBookingsCountWeek] =
    useState(0);
  const [deletedBookingsCountWeek, setDeletedBookingsCountWeek] = useState(0);

  const [newBookingsCountQuarter, setNewBookingsCountQuarter] = useState(0);
  const [completedBookingsCountQuarter, setCompletedBookingsCountQuarter] =
    useState(0);
  const [deletedBookingsCountQuarter, setDeletedBookingsCountQuarter] =
    useState(0);

  const [newBookingsCountAll, setNewBookingsCountAll] = useState(0);
  const [completedBookingsCountAll, setCompletedBookingsCountAll] = useState(0);
  const [deletedBookingsCountAll, setDeletedBookingsCountAll] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
  //Care givers data
  const [nannyCountWeek, setNannyCountWeek] = useState(0);
  const [nannyCountQuarter, setNannyCountQuarter] = useState(0);
  const [nannyCountAll, setNannyCountAll] = useState(0);

  const [cookCleanerCountWeek, setCookCleanerCountWeek] = useState(0);
  const [cookCleanerCountQuarter, setCookCleanerCountQuarter] = useState(0);
  const [cookCleanerCountAll, setCookCleanerCountAll] = useState(0);

  const [homeTutorCountWeek, setHomeTutorCountWeek] = useState(0);
  const [homeTutorCountQuarter, setHomeTutorCountQuarter] = useState(0);
  const [homeTutorCountAll, setHomeTutorCountAll] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await BookingAPI.getAll();

        // Get the current date and time
        const now = new Date();
        // Subtract 7 days from the current date
        const oneWeekAgo = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 7
        );
        // Subtract 90 days from the current date
        const threeMonthsAgo = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 90
        );

        // Filter the bookings from the last 7 days
        const filterBookingsFromLastWeek = (bookings, status) => {
          return bookings.filter((booking) => {
            // Convert date from "DD-MM-YYYY" to "MM-DD-YYYY"
            const [day, month, year] = booking.createdAt.split("-");
            const bookingDate = new Date(`${month}-${day}-${year}`);
            return (
              booking.isDeleted === (status === "DELETED") &&
              booking.isCompleted === (status === "COMPLETED") &&
              bookingDate >= oneWeekAgo &&
              bookingDate <= now
            );
          });
        };

        // Filter the bookings from the last 90 days
        const filterBookingsFromLastQuarter = (bookings, status) => {
          return bookings.filter((booking) => {
            // Convert date from "DD-MM-YYYY" to "MM-DD-YYYY"
            const [day, month, year] = booking.createdAt.split("-");
            const bookingDate = new Date(`${month}-${day}-${year}`);
            return (
              booking.isDeleted === (status === "DELETED") &&
              booking.isCompleted === (status === "COMPLETED") &&
              bookingDate >= threeMonthsAgo &&
              bookingDate <= now
            );
          });
        };

        // Filter all bookings by status
        const filterAllBookingsByStatus = (bookings, status) => {
          return bookings.filter((booking) => {
            return (
              booking.isDeleted === (status === "DELETED") &&
              booking.isCompleted === (status === "COMPLETED")
            );
          });
        };

        const newBookingsWeek = filterBookingsFromLastWeek(result, "NEW");
        const completedBookingsWeek = filterBookingsFromLastWeek(
          result,
          "COMPLETED"
        );
        const deletedBookingsWeek = filterBookingsFromLastWeek(
          result,
          "DELETED"
        );

        const newBookingsQuarter = filterBookingsFromLastQuarter(result, "NEW");
        const completedBookingsQuarter = filterBookingsFromLastQuarter(
          result,
          "COMPLETED"
        );
        const deletedBookingsQuarter = filterBookingsFromLastQuarter(
          result,
          "DELETED"
        );

        const newBookingsAll = filterAllBookingsByStatus(result, "NEW");
        const completedBookingsAll = filterAllBookingsByStatus(
          result,
          "COMPLETED"
        );
        const deletedBookingsAll = filterAllBookingsByStatus(result, "DELETED");

        setNewBookingsCountWeek(newBookingsWeek.length);
        setCompletedBookingsCountWeek(completedBookingsWeek.length);
        setDeletedBookingsCountWeek(deletedBookingsWeek.length);

        setNewBookingsCountQuarter(newBookingsQuarter.length);
        setCompletedBookingsCountQuarter(completedBookingsQuarter.length);
        setDeletedBookingsCountQuarter(deletedBookingsQuarter.length);

        setNewBookingsCountAll(newBookingsAll.length);
        setCompletedBookingsCountAll(completedBookingsAll.length);
        setDeletedBookingsCountAll(deletedBookingsAll.length);
      } catch (error) {
        console.error(error);
        setErrorMessage(error.message);
      } finally {
        setIsSpinnerVisible(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log(`ReportDashboard.js`)
    const fetchData = async (type) => {
      try {
        const result = await ServiceProviderAPI.getAll(
          type,
          false,
          false,
          true
        );

        // Get the current date and time
        const now = new Date();
        // Subtract 7 days from the current date
        const oneWeekAgo = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 7
        );
        // Subtract 90 days from the current date
        const threeMonthsAgo = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 90
        );

        // Filter the caregivers from the last 7 days
        const filterCaregiversFromLastWeek = (caregivers) => {
          return caregivers.filter((caregiver) => {
            // Convert date from "DD-MM-YYYY" to "MM-DD-YYYY"
            const [day, month, year] = caregiver.createdAt.split("-");
            const caregiverDate = new Date(`${month}-${day}-${year}`);
            return caregiverDate >= oneWeekAgo && caregiverDate <= now;
          });
        };

        // Filter the caregivers from the last 90 days
        const filterCaregiversFromLastQuarter = (caregivers) => {
          return caregivers.filter((caregiver) => {
            // Convert date from "DD-MM-YYYY" to "MM-DD-YYYY"
            const [day, month, year] = caregiver.createdAt.split("-");
            const caregiverDate = new Date(`${month}-${day}-${year}`);
            return caregiverDate >= threeMonthsAgo && caregiverDate <= now;
          });
        };

        const caregiversWeek = filterCaregiversFromLastWeek(result);
        const caregiversQuarter = filterCaregiversFromLastQuarter(result);

        return {
          week: caregiversWeek.length,
          quarter: caregiversQuarter.length,
          all: result.length,
        };
      } catch (error) {
        console.error(error);
        return {
          week: 0,
          quarter: 0,
          all: 0,
        };
      }
    };

    fetchData("nanny").then(({ week, quarter, all }) => {
      setNannyCountWeek(week);
      setNannyCountQuarter(quarter);
      setNannyCountAll(all);
    });

    fetchData("cookCleaner").then(({ week, quarter, all }) => {
      setCookCleanerCountWeek(week);
      setCookCleanerCountQuarter(quarter);
      setCookCleanerCountAll(all);
    });

    fetchData("homeTutor").then(({ week, quarter, all }) => {
      setHomeTutorCountWeek(week);
      setHomeTutorCountQuarter(quarter);
      setHomeTutorCountAll(all);
    });
  }, []);
  const WeeklyData = () => (
    <>
      <h2 className="text-xl md:text-3xl font-bold text-[#77425c] mb-4 sora">
        Weekly Data
      </h2>
      <div className="flex flex-wrap">
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <UserAddOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                New Booking
              </span>
            }
            value={newBookingsCountWeek}
          />{" "}
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <CheckCircleOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Completed Booking
              </span>
            }
            value={completedBookingsCountWeek}
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <DeleteOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Deleted Booking
              </span>
            }
            value={deletedBookingsCountWeek}
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <BookOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Total Booking
              </span>
            }
            value={
              newBookingsCountWeek +
              completedBookingsCountWeek +
              deletedBookingsCountWeek
            }
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <UserOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>Nanny</span>
            }
            value={nannyCountWeek}
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <UserOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Cook Cleaner
              </span>
            }
            value={cookCleanerCountWeek}
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <UserOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Home Tutor
              </span>
            }
            value={homeTutorCountWeek}
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <UserOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Total Caregivers
              </span>
            }
            value={nannyCountWeek + cookCleanerCountWeek + homeTutorCountWeek}
          />
        </div>

        <Space size={20} direction="horizontal"></Space>
      </div>
    </>
  );
  const QuarterlyData = () => (
    <>
      <h2 className="text-xl md:text-3xl font-bold text-[#77425c] mb-4 sora">
        Quarterly data
      </h2>
      <div className="flex flex-wrap">
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <UserAddOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                New Booking
              </span>
            }
            value={newBookingsCountQuarter}
          />{" "}
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <CheckCircleOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Completed Booking
              </span>
            }
            value={completedBookingsCountQuarter}
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <DeleteOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Deleted Booking
              </span>
            }
            value={deletedBookingsCountQuarter}
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <BookOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Total Booking
              </span>
            }
            value={
              newBookingsCountQuarter +
              completedBookingsCountQuarter +
              deletedBookingsCountQuarter
            }
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <UserOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>Nanny</span>
            }
            value={nannyCountQuarter}
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <UserOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Cook Cleaner
              </span>
            }
            value={cookCleanerCountQuarter}
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <UserOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Home Tutor
              </span>
            }
            value={homeTutorCountQuarter}
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <UserOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Total Caregivers
              </span>
            }
            value={
              nannyCountQuarter +
              cookCleanerCountQuarter +
              homeTutorCountQuarter
            }
          />
        </div>

        <Space size={20} direction="horizontal"></Space>
      </div>
    </>
  );
  const AllTimeData = () => (
    <>
      <h2 className="text-xl md:text-3xl font-bold text-[#77425c] mb-4 sora">
        Overall data
      </h2>
      <div className="flex flex-wrap">
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <UserAddOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                New Booking
              </span>
            }
            value={newBookingsCountAll}
          />{" "}
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <CheckCircleOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Completed Booking
              </span>
            }
            value={completedBookingsCountAll}
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <DeleteOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Deleted Booking
              </span>
            }
            value={deletedBookingsCountAll}
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <BookOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Total Booking
              </span>
            }
            value={
              newBookingsCountAll +
              completedBookingsCountAll +
              deletedBookingsCountAll
            }
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <UserOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>Nanny</span>
            }
            value={nannyCountAll}
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <UserOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Cook Cleaner
              </span>
            }
            value={cookCleanerCountAll}
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <UserOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Home Tutor
              </span>
            }
            value={homeTutorCountAll}
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-2">
          <DashboardCard
            icon={
              <UserOutlined
                style={{
                  color: "#CE257A",
                  backgroundColor: "white",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            }
            title={
              <span style={{ color: "white", fontFamily: "Sora" }}>
                Total Caregivers
              </span>
            }
            value={nannyCountAll + cookCleanerCountAll + homeTutorCountAll}
          />
        </div>

        <Space size={20} direction="horizontal"></Space>
        {/* <DashboardDoughuntChart /> */}
      </div>
    </>
  );
  return (
    <div>
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl md:text-3xl font-bold text-[#CE257A] mb-4 sora">
          Main Dashboard
        </h2>

        <Row type="flex" align="center" style={{ margin: "20px" }}>
          {errorMessage && (
            <Alert
              message={`Sorry an error occurred: ${errorMessage}`}
              type="error"
            />
          )}
          {isSpinnerVisible && <Spin />}
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <select
            value={timePeriod}
            onChange={(e) => setTimePeriod(e.target.value)}
            className="select-style"
          >
            <option value="weekly">Weekly</option>
            <option value="quarterly">Quarterly</option>
            <option value="all">All Time</option>
          </select>
        </div>

        {timePeriod === "weekly" && <WeeklyData />}
        {timePeriod === "quarterly" && <QuarterlyData />}
        {timePeriod === "all" && <AllTimeData />}
        <div className="flex flex-wrap">
          <CareGiverDashboardChart />
          <BookingStatusChart />
          {/* <Heatmap className="z-10" /> */}
        </div>
      </div>
    </div>
  );
};

//vertical line chart

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
//doghunt bar chart
ChartJS.register(ArcElement, Tooltip, Legend);

function DashboardCard({ title, value, icon }) {
  return (
    <Card
      size={100}
      className="w-full p-4 my-4"
      style={{ backgroundColor: "#CE257A" }}
    >
      <Space direction="horizontal">
        {icon}
        <Statistic
          title={title}
          value={value}
          valueStyle={{ color: "white", fontSize: 50 }}
          //  titleStyle={{ color: "white", fontSize: 50 }}
        />
      </Space>
    </Card>
  );
}

function CareGiverDashboardChart() {
  const [noOfCaregivers, setNoOfCaregivers] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const fetchData = async (type) => {
      try {
        const result = await ServiceProviderAPI.getAll(
          type,
          false,
          false,
          true
        );
        return result.length;
      } catch (error) {
        console.error(error);
        return 0;
      }
    };

    Promise.all([
      fetchData("nanny"),
      fetchData("cookCleaner"),
      fetchData("homeTutor"),
    ]).then(([nannyCount, cookCleanerCount, homeTutorCount]) => {
      setNoOfCaregivers({
        labels: ["Nanny", "Cook Cleaner", "Home Tutor"],
        datasets: [
          {
            label: "Number of Caregivers",
            data: [nannyCount, cookCleanerCount, homeTutorCount],
            backgroundColor: "#CE257A",
          },
        ],
      });
    });
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: {
            family: "Sora",
            size: window.innerWidth > 768 ? 18 : 12, // Larger font on larger screens
          },
        },
      },
      title: {
        display: true,
        text: "Total Number of Caregivers",
        font: {
          family: "Sora",
          size: window.innerWidth > 768 ? 24 : 16, // Larger font on larger screens
        },
      },
    },
  };

  return (
    <div
      style={{
        overflowX: window.innerWidth <= 768 ? "auto" : "visible",
        width: "100%",
        marginBottom: "100px",
      }}
    >
      {" "}
      {/* Add a margin to the bottom */}
      <Card
        style={{
          width: "100%",
          maxWidth: 800,
          height: 350,
          paddingTop: 50,
          marginTop: 20,
        }}
      >
        <Bar options={options} data={noOfCaregivers} />
      </Card>
    </div>
  );
}
function BookingStatusChart() {
  const [bookingStatusCounts, setBookingStatusCounts] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await BookingAPI.getAll();
        const newBookings = filterBookingsByStatus(result, "NEW");
        const completedBookings = filterBookingsByStatus(result, "COMPLETED");
        const deletedBookings = filterBookingsByStatus(result, "DELETED");

        setBookingStatusCounts({
          labels: ["New", "Completed", "Deleted"],
          datasets: [
            {
              label: "Number of Bookings",
              data: [
                newBookings.length,
                completedBookings.length,
                deletedBookings.length,
              ],
              backgroundColor: "#CE257A",
            },
          ],
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: {
            family: "Sora",
            size: window.innerWidth > 768 ? 18 : 12, // Larger font on larger screens
          },
        },
      },
      title: {
        display: true,
        text: "Total Number of Bookings by Status",
        font: {
          family: "Sora",
          size: window.innerWidth > 768 ? 24 : 16, // Larger font on larger screens
        },
      },
    },
  };

  return (
    <div
      style={{
        overflowX: window.innerWidth <= 768 ? "auto" : "visible",
        width: "100%",
        marginBottom: "100px",
      }}
    >
      {" "}
      {/* Add a margin to the bottom */}
      <Card
        style={{
          width: "100%",
          maxWidth: 800,
          height: 350,
          paddingTop: 50,
          marginTop: 20,
        }}
      >
        {" "}
        {/* Makes the chart larger on larger screens */}
        <Bar options={options} data={bookingStatusCounts} />
      </Card>
    </div>
  );
}

// function DashboardDoughuntChart() {
//   const [noOfCaregivers, setNoOfCaregivers] = useState({
//     labels: [],
//     datasets: [],
//   });
//   useEffect(() => {
//     fetch("https://dummyjson.com/carts")
//       .then((res) => res.json())
//       .then((res) => {
//         const labels = res.carts.map((cart) => {
//           return `Users-${cart.userId}`;
//         });
//         const data = res.carts.map((cart) => {
//           return cart.discountedTotal;
//         });

//         const dataSource = {
//           labels,
//           datasets: [
//             {
//               label: labels,
//               data: data,
//               backgroundColor: [
//                 "rgba(255, 99, 132, 0.2)",
//                 "rgba(54, 162, 235, 0.2)",
//                 "rgba(255, 206, 86, 0.2)",
//                 "rgba(75, 192, 192, 0.2)",
//                 "rgba(153, 102, 255, 0.2)",
//                 "rgba(255, 159, 64, 0.2)",
//               ],
//               borderColor: [
//                 "rgba(255, 99, 132, 1)",
//                 "rgba(54, 162, 235, 1)",
//                 "rgba(255, 206, 86, 1)",
//                 "rgba(75, 192, 192, 1)",
//                 "rgba(153, 102, 255, 1)",
//                 "rgba(255, 159, 64, 1)",
//               ],
//               borderWidth: 1,
//             },
//           ],
//         };
//         setNoOfCaregivers(dataSource);
//       });
//   }, []);

//   const options = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "bottom",
//       },
//       title: {
//         display: true,
//         text: "Types of Caregivers",
//       },
//     },
//   };

//   return (
//     <Card style={{ width: 600, height: 610, marginBottom: 20, marginTop: 20 }}>
//       <Doughnut options={options} data={noOfCaregivers} height={600} />
//     </Card>
//   );
// }

export default ReportDashboard;
