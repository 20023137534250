/* eslint-disable no-template-curly-in-string */
import React, {useState, useEffect} from "react";
import {
    Form,
    Alert,
    Button,
    Modal,
    Row,
    Col,
    Spin,
    Rate,
    message
} from 'antd';
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import {
    GetFormInputByType,
    getTextOfServiceProvider,
    validateFormInput
} from "../../../utils/helpers";
import {giveRatingAndReviewFormValues} from "./GiveRatingAndReviewFormValues";
import "./GiveRatingAndReview.css";
import {layout} from "./GiveRatingAndReview.style";
import {useNavigate, useParams} from "react-router-dom";
import {RatingAPI} from "../../../apis/ratingAPI";
// import {BookingAPI} from "../../../apis/bookingAPI";

const GiveRatingAndReview = () => {

    const {bookingId, serviceProviderName, typeOfServiceProvider} = useParams();

    const text = getTextOfServiceProvider(typeOfServiceProvider)
    const navigate = useNavigate();

    const [ratingInfo, setRatingInfo] = useState([]);
    // const [bookingInfo, setBookingInfo] = useState([]);
    const [rateAndReview, setRateAndReview] = useState([]);

    const [errorMessage, setErrorMessage] = useState('');
    const [isFormReadyToSubmit, setIsFormReadyToSubmit] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);


    useEffect(() => {
        RatingAPI
            .getByBookingId(bookingId, true)
            .then((response) => {
                setRatingInfo(response);
            })
            .catch((error) => {
                console.error(error);
                setRatingInfo(false);
            });
    }, [bookingId]);

    // TODO: For now, we don't need to retrieve the booking at this stage. If needed, check if the code works.
    // useEffect(() => {
    //     if (ratingInfo.id) {
    //         BookingAPI
    //             .get(bookingId, true)
    //             .then((response) => {
    //                 setBookingInfo(response);
    //             })
    //             .catch((error) => {
    //                 console.error(error);
    //                 setBookingInfo(false);
    //             });
    //     }
    // }, [bookingId, ratingInfo]);


    useEffect(() => {
        const handleAfterSaving = (response) => {
            message.success({
                content: `Rating and Review is successfully saved with id ${response.id}.`,
                duration: 10
            });
            navigate(`/${typeOfServiceProvider}`)
        }

        if (isFormReadyToSubmit) {
            RatingAPI.rate(rateAndReview, true)
                .then((response) => {
                    handleAfterSaving(response);
                })
                .catch((error) => {
                    setErrorMessage(error);
                })
                .finally(() => {
                    setIsSpinnerVisible(false);
                    setIsFormReadyToSubmit(false);
                });
        }
    }, [isFormReadyToSubmit, navigate, typeOfServiceProvider, rateAndReview]);

    const handleOk = () => {
        setIsFormReadyToSubmit(true);
        setIsModalVisible(false);
        setIsSpinnerVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (values) => {
        // Should format date value before submit.
        setRateAndReview({
            ...values.rateAndReview,
            bookingId: bookingId
        })
        setIsModalVisible(true);
    }

    return (
        ratingInfo ?
            <Col type="flex" align="center" style={{margin: '20px'}}>
                <Paragraph>You have already given rating for booking {bookingId}</Paragraph>

                Rating: <Rate value={ratingInfo.rating} />
                <Paragraph>Review: {ratingInfo.review}</Paragraph>

                <Paragraph>Thank you for your review!</Paragraph>
            </Col>
            :
            <>
                <Title level={3} style={{marginTop: '10px', marginBottom: '30px'}}>{text.ratingViewText}</Title>

                {errorMessage &&
                    <Alert message={`Saving rating and review failed with error ${errorMessage}`} type="error"/>}

                <Row type="flex" align="center" style={{margin: '20px'}}>
                    {isSpinnerVisible && <Spin/>}
                    <Paragraph>
                        Please give rating and review about {typeOfServiceProvider} {serviceProviderName} (Booking ID
                        = {bookingId})
                    </Paragraph>
                </Row>

                <Form {...layout}
                      name="rate-review-form"
                      onFinish={onFinish}
                      validateMessages={validateFormInput}>

                    {giveRatingAndReviewFormValues().map((item) =>
                        <GetFormInputByType item={item} key={item.name + item.enabledForTypeOfService}
                                            typeOfServiceProvider={typeOfServiceProvider} formName={'rateAndReview'}/>
                    )}

                    <Form.Item wrapperCol={{...layout.wrapperCol, offset: 8}}>
                        <Button type="primary" htmlType="submit">
                            Submit Rating and Review
                        </Button>
                    </Form.Item>
                </Form>

                <Modal title="Confirmation" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <p>Are you sure you have fully completed the required fields?</p>
                </Modal>
            </>
    );
};

export default GiveRatingAndReview;
