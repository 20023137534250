import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../Language/LanguageContext";
import { languageContent } from "../Language/languageContent";
import heroVid from "../../assets/heroVid.mp4";
import heroImg from "../../assets/heroImg.jpg";
//import BkgImage from "../../assets/bkg.png";

const Hero = () => {
  const { language } = useContext(LanguageContext);
  const { header, servicesText } = languageContent[language];
  const [isConnectionSlow, setIsConnectionSlow] = useState(false);

  useEffect(() => {
    const handleConnectionChange = () => {
      const connection =
        navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection;
      if (connection) {
        setIsConnectionSlow(connection.downlink < 0.1); // set threshold to 0.1 Mbps (100 Kbps).
      }
    };

    handleConnectionChange();
    if (window && window.addEventListener) {
      window.addEventListener("online", handleConnectionChange);
      window.addEventListener("offline", handleConnectionChange);
    }

    return () => {
      if (window && window.removeEventListener) {
        window.removeEventListener("online", handleConnectionChange);
        window.removeEventListener("offline", handleConnectionChange);
      }
    };
  }, []);

  return (
    <div className="text-white">
      <div
        className="w-full mx-auto text-center flex flex-col justify-center relative z-5"
        style={{ height: 860 }}
      >
        {isConnectionSlow ? (
          <img
            src={heroImg}
            alt="hero"
            className="w-full h-full object-cover"
          />
        ) : (
          <video
            className="w-full h-full object-cover"
            src={heroVid}
            autoPlay
            loop
            muted
          />
        )}
        <div
          className="absolute w-full h-full top-0 left-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        ></div>
        <div className="absolute top-0 w-full h-full flex flex-col justify-center text-center text-white p-4">
          <div className="flex justify-center items-center">
            <p className="md:text-5xl sm:text-4xl text-xl font-bold py-4">
              {header}
            </p>
          </div>
          <p className="md:text-xl text-xl font-bold text-white">
            {servicesText}
          </p>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="text-white">
  //     <div
  //       className="w-full mx-auto text-center flex flex-col justify-center relative z-5 "
  //       style={{ height: 100, backgroundColor: "lightgrey" }}
  //     >
  //       <div
  //         className="w-full py-[10rem] px-4 bg-white"
  //         style={{
  //           backgroundImage: `url(${BkgImage})`,
  //           backgroundSize: "cover",
  //           backgroundRepeat: "no-repeat",
  //           backgroundPosition: "center",
  //           // minHeight: "100vh", // Ensure the div takes up the full height of the viewport
  //         }}
  //       ></div>
  //     </div>
  //   </div>
  // );
};

export default Hero;
