/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Table,
  Tag,
  Space,
  Modal,
  Button,
  Alert,
  Spin,
  Row,
  Switch,
  message,
  Popconfirm,
  Typography,
  Image,
} from "antd";
import EditableTable from "../../../components/EditableTable/EditableTable";
import Title from "antd/es/typography/Title";
import { listColumns } from "./listColumns";
import { getTextOfServiceProvider } from "../../../utils/helpers";
import { ServiceProviderAPI } from "../../../apis/serviceProviderAPI";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import "../AdminManageBookings/ManageBookings.css";
import { useNavigate, useParams } from "react-router-dom";

const AdminListServiceProvider = () => {

  const { typeOfServiceProvider } = useParams(); 
  console.log("Current typeOfServiceProvider:", typeOfServiceProvider);
  const text = getTextOfServiceProvider(typeOfServiceProvider);

  const [serviceProviderList, setServiceProviderList] = useState([]);

  const [isFormReadyToSubmit, setIsFormReadyToSubmit] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [idToBeDeleted, setIdToBeDeleted] = useState();
  const [id, setId] = useState();

  const [isFlagUpdated, setIsFlagUpdated] = useState(false);
  const [updatedFlag, setUpdatedFlag] = useState();
  const [updatedProperty, setUpdatedProperty] = useState();

  const handleOk = () => {
    setIsFormReadyToSubmit(true);
    setIsModalVisible(false);
    setIsSpinnerVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDeleteClick = (id) => {
    setIdToBeDeleted(id);
    setIsModalVisible(true);
  };

  const handleUpdatedFlag = (property, id, value) => {
    setIsFlagUpdated(true);
    setId(id);
    setUpdatedFlag(value);
    setUpdatedProperty(property);
  };

  const columns = listColumns(
    typeOfServiceProvider,
    handleUpdatedFlag,
    handleDeleteClick
  );

  useEffect(() => {
    fetchData();
  }, [typeOfServiceProvider]);

  const fetchData = async () => {
    setIsSpinnerVisible(true);

    console.log(`AdminListServiceProvider.js`)

    try {
      const result = await ServiceProviderAPI.getAll(
        typeOfServiceProvider,
        false,
        false,
        true
      );
      // console.log("Result length:", result.length);
      setServiceProviderList(result);
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message);
      setServiceProviderList([]);
    } finally {
      setIsSpinnerVisible(false);
    }
  };

  useEffect(() => {
    isFlagUpdated && updateFlagAPI();
  }, [isFlagUpdated]);

  const updateFlagAPI = async () => {
    setIsSpinnerVisible(true);

    try {
      await ServiceProviderAPI.updateFlag(
        typeOfServiceProvider,
        id,
        updatedProperty,
        updatedFlag
      );
      await fetchData();
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message);
    } finally {
      setUpdatedFlag(null);
      setIsFlagUpdated(false);
      setIsSpinnerVisible(false);
    }
  };

  useEffect(() => {
    const handleAfterSaving = () => {
      message.success({
        content: `${text.serviceNamePascalCase} with id ${idToBeDeleted} is successfully deleted. Please refresh the page`,
        duration: 5,
      });
      fetchData(); // reload the list after item is removed.
    };

    if (isFormReadyToSubmit && idToBeDeleted) {
      ServiceProviderAPI.delete(typeOfServiceProvider, idToBeDeleted)
        .then(() => {
          handleAfterSaving();
        })
        .catch((error) => {
          setErrorMessage(error.message);
        })
        .finally(() => {
          setIsFormReadyToSubmit(false);
          setIdToBeDeleted(null);
        });
    }
  }, [isFormReadyToSubmit, idToBeDeleted, history]);

  const updateData = async (id, serviceProvider) => {
    setIsSpinnerVisible(true);

    try {
      await ServiceProviderAPI.updateData(
        typeOfServiceProvider,
        id,
        serviceProvider
      );
      await fetchData();
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message);
    } finally {
      setIsSpinnerVisible(false);
    }
  };

  return (
    <>
      <Row type="flex" align="center" style={{ margin: "20px" }}>
        {errorMessage && (
          <Alert
            message={`Sorry an error occurred: error ${errorMessage}`}
            type="error"
          />
        )}
        {isSpinnerVisible && (
          <div className="star-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </Row>

      <Title
        level={3}
        style={{
          marginTop: "10px",
          marginBottom: "30px",
          color: "#CE257A",
          fontFamily: "Sora",
        }}
      >
        {text.listViewText} ({serviceProviderList && serviceProviderList.length}
        )
      </Title>
      {/* <div className="scrollable-table"> */}
      {serviceProviderList && (
        <EditableTable
          originData={serviceProviderList}
          originalColumns={columns}
          updateData={updateData}
          expandable={{
            expandedRowRender: (record) => (
              <div>
                <h3 className="text-[#CE257A] font-sora">About Me</h3>
                <p style={{ margin: 0 }} className="font-sora">
                  {record.aboutMe}
                </p>
              </div>
            ),
            // If aboutMe is available (not null, not blank), display it
            rowExpandable: (record) => record.aboutMe,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <DownOutlined
                  onClick={(e) => onExpand(record, e)}
                  style={{ color: "#CE257A" }}
                />
              ) : (
                <RightOutlined
                  onClick={(e) => onExpand(record, e)}
                  style={{ color: "#CE257A" }}
                />
              ),
          }}
          //   scroll={{ x: 1500 }} // setting y will make the table small in length
          //  responsive={true}
          style={{
            display: "flex",
            flex: 1,
            margin: 10,
            backgroundColor: "white",
          }}
        />
      )}
      {/* </div> */}
      <Modal
        title={<span className="text-[#CE257A] font-sora">Confirmation</span>}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="font-sora"
        okButtonProps={{
          className:
            "bg-[#CE257A] text-white hover:bg-brown-500 transition-colors duration-300",
        }}
        cancelButtonProps={{
          className:
            "bg-[#CE257A] text-white hover:bg-brown-500 transition-colors duration-300",
        }}
      >
        <p>Are you sure you want to delete this {text.serviceName}?</p>
      </Modal>
    </>
  );
};

export default AdminListServiceProvider;
