import React from "react";
import { Row, Col, Card, Avatar, Divider, Typography } from "antd";

import logo from "../../../assets/logo.png";
const { Meta } = Card;

const ProfilePage = () => {
  return (
    <div className="container">
      <Typography.Title level={1} style={{ color: "#CE257A" }} className="sora">
        Profile
      </Typography.Title>
      <Row justify="center">
        <Col xs={24} sm={24} md={18} lg={12}>
          <Card>
            <Meta
              className="sora"
              avatar={<Avatar src={logo} />}
              title={
                <span style={{ color: "#CE257A", fontFamily: "Sora" }}>
                  Mogzit Admin
                </span>
              }
              description="Super Admin Access"
            />
          </Card>
        </Col>
      </Row>
      <Divider />
      <Row justify="center">
        <Col xs={24} sm={24} md={20} lg={20}>
          <Card style={{ color: "#ffffff", backgroundColor: "#CE257A" }}>
            <h2 className="text-xl font-sora">Authority</h2>
            <p className=" font-sora">
              Add, Edit, Delete, Can access all data of caregivers
            </p>
          </Card>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24} sm={24} md={20} lg={20} style={{ marginTop: 10 }}>
          <Card style={{ color: "#ffffff", backgroundColor: "#CE257A" }}>
            <h2 className="text-xl font-sora">Contact Information</h2>
            <p className=" font-sora">Email: mogzitinhomecare.info@gmail.com</p>
            <p className=" font-sora">Phone: +251 902 993278</p>
          </Card>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={20}
          lg={20}
          style={{ marginTop: 10, marginBottom: 10 }}
        >
          <Card style={{ color: "#ffffff", backgroundColor: "#CE257A" }}>
            <h2 className="text-xl font-sora">Social Media</h2>
            <p className=" font-sora">
              Facebook: https://www.facebook.com/mogzit.inhomecare/
            </p>
            <p className=" font-sora">
              Instagram: https://www.instagram.com/mogzit.inhomecare/?hl=en
            </p>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ProfilePage;
