import { Button } from "antd"
import UserService from "../../services/UserService"

import { CaretRightOutlined } from "@ant-design/icons";
const Welcome = () => (
  <div>
    <h1 style={{ color: "brown" }}>Hello Anonymous!</h1>
    <p style={{ color: "brown" }}>Please authenticate yourself!</p>

    <Button
      type="primary"
      icon={<CaretRightOutlined />}
      size={"large"}
      onClick={() => UserService.doLogin()}
      className="pink-button"
    >
      Login
    </Button>
  </div>
)

export default Welcome
