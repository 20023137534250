import { DatePicker, Form, Input, InputNumber, Rate, Select } from "antd";
import React from "react";
import Radio from "antd/es/radio/radio";
import moment from "moment";

export const getImageUploadPresetForCloudinary = (typeOfServiceProvider) =>
  `${typeOfServiceProvider}-profile-photos`;

export const toFirstCharUpperCase = (name) =>
  name.charAt(0).toUpperCase() + name.slice(1).trim();

export const ageCalculator = (yearOfBirth) =>
  new Date().getFullYear() - yearOfBirth;
//fixed undefined (reading ‘charAt’)
export const constructFullName = (firstName, middleName, lastName) => {
  return `${toFirstCharUpperCase(firstName || "")} ${toFirstCharUpperCase(
    middleName || ""
  )} ${toFirstCharUpperCase(lastName || "")}`.trim();
};

export const getNameInitials = (name) => {
  return name
    .trim()
    .split(" ")
    .reduce((acc, subname) => acc + subname[0], "");
};

// eslint-disable-next-line no-unused-vars
function getNameInitialsAdonias(name) {
  if (!name) {
    return "";
  }
  const parts = name.split(" ");
  let initials = "";
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].length > 0 && parts[i] !== "") {
      initials += parts[i][0];
    }
  }
  return initials;
}

export const getNameInitialsWithDot = (name) => {
  return name
    .trim()
    .split(" ")
    .reduce((acc, subname) => acc + subname[0] + ".", "");
};

export const getTextOfServiceProvider = (typeOfServiceProvider) => {
  switch (typeOfServiceProvider) {
    case "nanny":
      return {
        addViewText: "Add a Nanny",
        listViewText: "List Nannies",
        detailViewText: "Nanny Info",
        title: "Nanny Info",
        bookLabel: "Book Nanny",
        notFound: "Nanny not found",
        serviceName: "nanny",
        serviceNamePascalCase: "Nanny",
        serviceNamePlural: "nannies",
        ratingViewText: "Rate your Nanny",
      };
    case "cookCleaner":
      return {
        addViewText: "Add a Cook and Cleaner",
        listViewText: "List Cooks and Cleaners",
        detailViewText: "Cook and Cleaner Info",
        title: "Cook and Cleaner Info",
        bookLabel: "Book Cook/Cleaner",
        notFound: "CookCleaner not found",
        serviceName: "cook and cleaner",
        serviceNamePascalCase: "Cook and Cleaner",
        serviceNamePlural: "cooks and cleaners",
        ratingViewText: "Rate your Cook and Cleaner",
      };
    case "homeTutor":
      return {
        addViewText: "Add a Home Tutor",
        listViewText: "List Home Tutors",
        detailViewText: "Home Tutor Info",
        title: "Home Tutor Info",
        bookLabel: "Book Home Tutor",
        notFound: "Home Tutor not found",
        serviceName: "home tutor",
        serviceNamePascalCase: "Home Tutor",
        serviceNamePlural: "home Tutors",
        ratingViewText: "Rate your Home Tutor",
      };
    default:
      return null;
  }
};

export const getBookingTypeByServiceProvider = (typeOfServiceProvider) => {
  switch (typeOfServiceProvider) {
    case "nanny":
      return "NANNY";
    case "cookCleaner":
      return "COOK_CLEANER";
    case "homeTutor":
      return "HOME_TUTOR";
    default:
      return null;
  }
};

/* eslint-disable no-template-curly-in-string */
export const validateFormInput = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
    date: "${label} is not a valid date!",
    select: "${label} is not a valid select option!",
    string: "${label} is not a valid string!",
    integer: "${label} is not a valid integer!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

export const GetFormInputByType = ({
  item,
  typeOfServiceProvider,
  formName,
}) => {
  const { Option } = Select;

  // If enabledForTypeOfService is provided and does not include the current type of service, do not display the form element.
  if (
    item.enabledForTypeOfService &&
    !item.enabledForTypeOfService.includes(typeOfServiceProvider)
  ) {
    return null;
  } else {
    switch (item.type) {
      case "textArea":
        return (
          <Form.Item
            key={item.name}
            name={[formName, item.name]}
            label={
              <span style={{ color: "#CE257A", fontFamily: "Sora" }}>
                {item.label}
              </span>
            }
            rules={[...item.rules]}
          >
            <Input.TextArea
              placeholder={item.placeholder}
              size={"medium"}
              rows={item.rows || 2}
            />
          </Form.Item>
        );
      case "number":
        return (
          <Form.Item
            key={item.name}
            name={[formName, item.name]}
            label={
              <span style={{ color: "#CE257A", fontFamily: "Sora" }}>
                {item.label}
              </span>
            }
            rules={[...item.rules]}
          >
            <InputNumber />
          </Form.Item>
        );
      case "date":
        // eslint-disable-next-line no-unused-vars
        const yearFormat = "YYYY";
        return (
          <Form.Item
            key={item.name}
            name={[formName, item.name]}
            label={
              <span style={{ color: "#CE257A", fontFamily: "Sora" }}>
                {item.label}
              </span>
            }
            rules={[
              ...item.rules,
              {
                validator: (_, value) => {
                  const currentYear = moment().year();
                  const inputYear = value.year();
                  const age = currentYear - inputYear;

                  if (age >= 18) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error("Age is not valid to work")
                    );
                  }
                },
              },
            ]}
          >
            <DatePicker picker="year" />

            {/*{item.minDate && <DatePicker picker="year" disabledDate={d => !d || d.isSameOrBefore(item.minDate)} initialValues={moment(item.minDate, yearFormat)}/> }*/}
          </Form.Item>
        );
      case "radio":
        return (
          <Form.Item
            key={item.name}
            name={[formName, item.name]}
            label={
              <span style={{ color: "#CE257A", fontFamily: "Sora" }}>
                {item.label}
              </span>
            }
            rules={[...item.rules]}
          >
            <Radio.Group>
              {item.values.map((it) => (
                <Radio value={it.value} key={it.value}>
                  <span style={{ color: "#CE257A", fontFamily: "Sora" }}>
                    {it.label}
                  </span>
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        );
      case "select":
        return (
          <Form.Item
            key={item.name}
            name={[formName, item.name]}
            label={
              <span style={{ color: "#CE257A", fontFamily: "Sora" }}>
                {item.label}
              </span>
            }
            rules={[...item.rules]}
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder={item.placeholder}
            >
              {item.values.map((it) => (
                <Option value={it.value} key={it.value}>
                  {it.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      case "rate":
        return (
          <Form.Item
            key={item.name}
            name={[formName, item.name]}
            label={
              <span style={{ color: "#CE257A", fontFamily: "Sora" }}>
                {item.label}
              </span>
            }
            rules={[...item.rules]}
          >
            <Rate
              tooltips={item.ratingDescription}
              value={[formName, item.name]}
            />
          </Form.Item>
        );
      default:
        return (
          <Form.Item
            key={item.name}
            name={[formName, item.name]}
            label={
              <span style={{ color: "#CE257A", fontFamily: "Sora" }}>
                {item.label}
              </span>
            }
            rules={[...item.rules]}
          >
            <Input placeholder={item.placeholder} />
          </Form.Item>
        );
    }
  }
};
