import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./index.css";
import UserService from "./services/UserService";

// import { BrowserRouter } from "react-router-dom";
// const root = ReactDOM.createRoot(document.getElementById("root"));
// const renderApp = () => root.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// );

const renderApp = () => ReactDOM.createRoot(
  document.getElementById('root')
).render(
  <React.StrictMode>
      <App/>
  </React.StrictMode>
);

console.log("Initializing Keyclock")
UserService.initKeycloak(renderApp);
console.log("Initialized Keyclock")