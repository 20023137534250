export const filterBookingsByStatus = (bookings, bookingStatus) => {
    switch (bookingStatus) {
        case 'NEW':
            return bookings.filter((booking) => {
                    return !booking.isDeleted && !booking.isCompleted
                }
            )
        case 'COMPLETED':
            return bookings.filter((booking) => {
                    return booking.isCompleted && !booking.isDeleted
                }
            )
        case 'DELETED':
            return bookings.filter((booking) => {
                    return booking.isDeleted
                }
            )
        default:
            return []
    }
}
