import Keycloak from "keycloak-js";

// Reading note for another option of impl
// https://www.keycloak.org/docs/latest/securing_apps/#using-the-adapter

// Get the realm name from the URL and use it for changing the default realm
const realm = 'mogzitbooking'; //window.location.hostname.split('.')[0];
console.log(`window.location.origin `, window.location.origin)
console.log(`hostname in UserService: ${window.location.hostname}`);
console.log(`realm in UserService: ${realm}`);

const keyCloakConfig = {
    url: 'https://euc1.auth.ac/auth',
    realm: realm,
    clientId: 'MogzitBookingWeb'
};

const _kc = new Keycloak(keyCloakConfig);

console.log(JSON.stringify(_kc));

// const _kc = new Keycloak({
//     url: 'http://localhost:8080',
//     realm: realm,
//     clientId: 'client-one'
// });

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
    _kc.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        // bearerExcludedUrls: [
        //     'admin$'
        //   ]
    })
        .then((authenticated) => {
            if (!authenticated) {
                console.log("user is not authenticated.");
            }
            console.log("user is authenticated.");
            // console.log(getRoles());
            onAuthenticatedCallback();
        })
        .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getRealm = () => realm;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
    _kc.updateToken(5)
        .then(successCallback)
        .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getRoles = () => _kc.realmAccess.roles.filter((role) => role.startsWith('Mogzit'));

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getRealm,
    getToken,
    updateToken,
    getUsername,
    getRoles,
    hasRole,
};

export default UserService;
