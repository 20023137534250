/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Table, Modal, Alert, Spin, Row, message } from "antd";
import { useNavigate } from "react-router-dom";
import Title from "antd/es/typography/Title";
import { columns } from "./columns";
import { BookingAPI } from "../../../apis/bookingAPI";
import { filterBookingsByStatus } from "./bookingHelpers";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import "../AdminManageBookings/ManageBookings.css";
// bookingStatus can be 'NEW', 'COMPLETED', 'DELETED'
const ManageBookings = ({ bookingStatus, title }) => {
  let history = useNavigate();

  const [bookingList, setBookingList] = useState([]);

  const [isFormReadyToSubmit, setIsFormReadyToSubmit] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [idToBeDeleted, setIdToBeDeleted] = useState();
  const [idToBeCompleted, setIdToBeCompleted] = useState();

  const handleOk = () => {
    setIsFormReadyToSubmit(true);
    setIsModalVisible(false);
    setIsSpinnerVisible(true);
  };

  const handleCancel = () => {
    setIdToBeDeleted(null);
    setIsModalVisible(false);
  };

  const handleDeleteClick = (id) => {
    setIdToBeDeleted(id);
    setIsModalVisible(true);
  };

  const handleCompleteClick = (id) => {
    setIdToBeCompleted(id);
    setIsModalVisible(true);
  };

  const showSuccessMessage = (id, action) => {
    return message.success({
      content: `Booking with id ${id} is successfully ${action}. Please refresh the page`,
      duration: 5,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (isFormReadyToSubmit && idToBeDeleted) {
      BookingAPI.delete(idToBeDeleted)
        .then(() => {
          showSuccessMessage(idToBeDeleted, "deleted");
          fetchData(); // reload the list after item is removed.
        })
        .catch((error) => {
          setErrorMessage(error.message);
        })
        .finally(() => {
          setIsFormReadyToSubmit(false);
          setIsModalVisible(false);
          setIdToBeDeleted(null);
        });
    }
  }, [isFormReadyToSubmit, idToBeDeleted, history]);

  useEffect(() => {
    if (isFormReadyToSubmit && idToBeCompleted) {
      BookingAPI.complete(idToBeCompleted, true)
        .then(() => {
          showSuccessMessage(idToBeCompleted, "completed");
          fetchData();
        })
        .catch((error) => {
          setErrorMessage(error.toString);
        })
        .finally(() => {
          setIsFormReadyToSubmit(false);
          setIsModalVisible(false);
          setIdToBeCompleted(null);
        });
    }
  }, [isFormReadyToSubmit, idToBeCompleted, history]);

  const fetchData = async () => {
    setIsSpinnerVisible(true);

    try {
      const result = await BookingAPI.getAll();
      const filteredBookings = filterBookingsByStatus(result, bookingStatus);
      setBookingList(filteredBookings);
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message);
      setBookingList([]);
    } finally {
      setIsSpinnerVisible(false);
    }
  };

  return (
    <>
      <Row
        type="flex"
        align="center"
        style={{ margin: "20px", color: "#CE257A", fontFamily: "Sora" }}
      >
        {errorMessage && (
          <Alert
            message={`Sorry an error occurred: error ${errorMessage}`}
            type="error"
          />
        )}
        {/* {isSpinnerVisible && <Spin />} */}
        {isSpinnerVisible && (
          <div class="star-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </Row>

      <>
        <Title
          level={3}
          style={{
            marginTop: "10px",
            marginBottom: "30px",
            color: "#CE257A",
            fontFamily: "Sora",
          }}
        >
          {title} ({bookingList && bookingList.length})
        </Title>
        <div className="overflow-x-auto">
          <Table
            columns={columns(
              handleCompleteClick,
              handleDeleteClick,
              bookingStatus
            )}
            dataSource={bookingList}
            rowKey="id"
            expandable={{
              expandedRowRender: (record) => (
                <div>
                  <h3 className="font-sora text-[#CE257A]">Message</h3>
                  <p className="font-sora m-0">{record.message}</p>
                </div>
              ),
              rowExpandable: (record) => record.message,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <DownOutlined
                    onClick={(e) => onExpand(record, e)}
                    style={{ color: "#CE257A" }}
                  />
                ) : (
                  <RightOutlined
                    onClick={(e) => onExpand(record, e)}
                    style={{ color: "#CE257A" }}
                  />
                ),
            }}
          />
        </div>
      </>

      <Modal
        title={<span className="text-[#CE257A] font-sora">Confirmation</span>}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          Are you sure you want to {idToBeDeleted && `delete`}{" "}
          {idToBeCompleted && `complete`} this booking?
        </p>
      </Modal>
      <Modal
        title="Confirmation"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="font-sora"
        okButtonProps={{
          className:
            "bg-[#CE257A] text-white hover:bg-brown-500 transition-colors duration-300",
        }}
        cancelButtonProps={{
          className:
            "bg-[#CE257A] text-white hover:bg-brown-500 transition-colors duration-300",
        }}
      >
        <p className="font-sora">
          Are you sure you want to {idToBeDeleted && "delete"}{" "}
          {idToBeCompleted && "complete"} this booking?
        </p>
      </Modal>
    </>
  );
};

export default ManageBookings;
