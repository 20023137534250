import React from "react";
import UserDashboard from "./pages/user/UserDashboard/UserDashboard";

import { Route, Routes, BrowserRouter } from "react-router-dom";
import AdminDashboard from "./pages/admin/AdminDashboard/AdminDashboard";

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path='/admin/*' element={<AdminDashboard />} />
          <Route path='*' element={<UserDashboard />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
