import React, { useState, useContext, Fragment } from "react";

import { CgMenuMotion, CgClose } from "react-icons/cg";
import { FaAngleDown } from "react-icons/fa6";
import logo from "../../assets/logo.png";
import ethFlag from "../../assets/eth.png";

import usFlag from "../../assets/us.png";

import { Menu, Transition } from "@headlessui/react";
import { LanguageContext } from "../Language/LanguageContext";
import { languageContent } from "../Language/languageContent";
import { FaFacebook, FaInstagram, FaEnvelope } from "react-icons/fa";
import "../../index.css";
import "./Navbar.css";

//for hovering effect on dropdown
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  function navigate(path) {
    window.location.href = window.location.origin + path;
  }

  //setting mobile menu
  const [click, setClick] = useState(false);

  const handleClick = () => {
    if (window.innerWidth <= 940) {
      // Check if the window's width is less than or equal to 940px
      setClick(!click);
      if (!click) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    }
  };

  //changing language
  const { language, setLanguage } = useContext(LanguageContext);

  const toggleLanguage = () => {
    setLanguage(language === "en" ? "am" : "en");
  };
  //LAnguages

  const {
    home,
    about,
    services,
    contact,
    register,
    bookNanny,
    bookHomeTutor,
    becomeACG,
  } = languageContent[language];
  //change nav bar when scrolling
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  window.addEventListener("scroll", changeColor);

  const closeMenuAndNavigate = (event, url) => {
    event.preventDefault();
    setClick(false);
    window.location.href = url;
  };
  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      <div className={color ? "header header-bg" : "header"}>
        <nav className="navbar">
          <a href="https://mogzit.com/" className="logo">
            <img src={logo} alt="logo" id="logo" />
          </a>
          <div className="hamburger" onClick={handleClick}>
            {click ? (
              <CgClose size={30} style={{ color: "#ffffff", opacity: 0 }} />
            ) : (
              <CgMenuMotion size={30} style={{ color: "#ffffff" }} />
            )}
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            {click && ( //display logo when mobile menu is open
              <li className="nav-item">
                <CgClose
                  size={23}
                  style={{
                    color: "#ffffff",
                    position: "absolute",
                    top: 20,
                    right: 20,
                  }}
                  onClick={() => {
                    setClick(false);
                    handleClick();
                  }}
                />
                <a
                  href="https://mogzit.com/"
                  onClick={(event) => {
                    closeMenuAndNavigate(event, "https://mogzit.com/");
                    handleClick();
                  }}
                >
                  <img src={logo} alt="logo" id="Menuogo" />
                </a>
              </li>
            )}
            <li className="nav-item">
              <a
                href="https://mogzit.com/"
                onClick={(event) => {
                  closeMenuAndNavigate(event, "https://mogzit.com/");
                  handleClick();
                }}
              >
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button className="inline-flex justify-center items-center">
                    <p className="custom-underline " style={{ color: "white" }}>
                      {home}
                    </p>
                  </Menu.Button>
                </Menu>
              </a>
            </li>

            <li className="nav-item z-50">
              <a
                href="https://mogzit.com/nanny-service-ethiopia/"
                onClick={(event) => {
                  closeMenuAndNavigate(
                    event,
                    "https://mogzit.com/nanny-service-ethiopia/"
                  );
                  handleClick();
                }}
              >
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button
                    className="inline-flex justify-center items-center"
                    onClick={(event) => {
                      event.stopPropagation(); // Prevent the click event from reaching the parent link
                    }}
                  >
                    <p className="custom-underline " style={{ color: "white" }}>
                      {" "}
                      {services}
                    </p>
                    <FaAngleDown
                      className="-mr-1 ml-2 h-3 w-3"
                      aria-hidden="true"
                      style={{ color: "white", marginLeft: "10px" }}
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-100"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white divide-y divide-gray-100 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <p
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                              onClick={(event) => {
                                event.preventDefault();

                                navigate("/nanny");
                                setClick(false);
                                event.stopPropagation();
                                handleClick();
                              }}
                            >
                              {bookNanny}
                            </p>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <p
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                              onClick={(event) => {
                                event.preventDefault();

                                navigate("/homeTutor");
                                setClick(false);
                                event.stopPropagation();
                                handleClick();
                              }}
                            >
                              {bookHomeTutor}
                            </p>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </a>
            </li>
            <li className="nav-item ">
              <a
                href="https://mogzit.com/about-care/"
                onClick={(event) => {
                  closeMenuAndNavigate(event, "https://mogzit.com/about-care/");
                  handleClick();
                }}
              >
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button className="inline-flex justify-center items-center">
                    <p className="custom-underline " style={{ color: "white" }}>
                      {about}
                    </p>
                  </Menu.Button>
                </Menu>
              </a>
            </li>

            <li className="nav-item">
              <a
                href="https://mogzit.com/contact/"
                onClick={(event) => {
                  closeMenuAndNavigate(event, "https://mogzit.com/contact/");
                  handleClick();
                }}
              >
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button className="inline-flex justify-center items-center">
                    <p className="custom-underline " style={{ color: "white" }}>
                      {contact}
                    </p>
                  </Menu.Button>
                </Menu>
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                toggleLanguage();
                setClick(false);
                handleClick();
              }}
            >
              {/* <a href="#" onClick={closeMenu}> */}
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="inline-flex justify-center items-center">
                  <p className="custom-underline ">
                    {language === "en" ? (
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <img id="flag" src={ethFlag} alt="Ethiopian Flag" />
                        <span style={{ color: "white" }}>አማ</span>
                      </span>
                    ) : (
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <img id="flag" src={usFlag} alt="US Flag" />
                        <span style={{ color: "white" }}>EN</span>
                      </span>
                    )}
                  </p>
                </Menu.Button>
              </Menu>
              {/* </a> */}
            </li>
            {click && ( // only display "Become a Nanny" when mobile menu is open
              <li className="nav-item">
                <p style={{ color: "white" }}> {becomeACG}:</p>{" "}
                <a
                  href="https://mogzit.com/contact/"
                  onClick={(event) => {
                    closeMenuAndNavigate(event, "https://mogzit.com/contact/");
                    handleClick();
                  }}
                >
                  <button className="register-button">{register}</button>
                </a>
              </li>
            )}

            {!click && (
              <a
                href="https://mogzit.com/contact/"
                onClick={(event) => {
                  closeMenuAndNavigate(event, "https://mogzit.com/contact/");
                  handleClick();
                }}
              >
                <button className="register-button ">{register}</button>
              </a>
            )}

            {click && ( // only display social media icons when mobile menu is open
              <li
                className="nav-item"
                onClick={() => {
                  setClick(false);
                  handleClick();
                }}
              >
                <div className="mb-0.5 flex justify-between w-24">
                  <a
                    href="https://www.facebook.com/mogzit.inhomecare/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "20px" }}
                  >
                    <FaFacebook size={30} style={{ color: "#CE257A" }} />{" "}
                    {/* Facebook icon */}
                  </a>
                  <a
                    href="https://www.instagram.com/mogzit.inhomecare/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "20px" }}
                  >
                    <FaInstagram size={30} style={{ color: "#CE257A" }} />{" "}
                    {/* Instagram icon */}
                  </a>
                  <a
                    href="mailto:mogzitinhomecare.info@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "20px" }}
                  >
                    <FaEnvelope size={30} style={{ color: "#CE257A" }} />{" "}
                    {/* Gmail icon */}
                  </a>
                </div>
              </li>
            )}
          </ul>
        </nav>
      </div>
      {/* </Router> */}
    </LanguageContext.Provider>
  );
};

export default Navbar;
