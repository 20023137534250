import React, { useEffect, useState } from "react";
import { Table, Popconfirm, Form, Typography, Button } from "antd";
import EditableCell from "../EditableTableCell/EditableTableCell";
import "./EditableTable.css";

const EditableTable = ({
  originData,
  originalColumns,
  updateData,
  expandable,
  scroll,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editableRowKey, setEditableRowKey] = useState("");
  const [updatedId, setUpdatedId] = useState();
  const [updatedItem, setUpdatedItem] = useState();

  useEffect(() => {
    setData(originData);
  }, [originData]);

  useEffect(() => {
    //TODO: Create a reusable modal window for confirmation
    //TODO: Show modal window before updating
    if (updatedId && updatedItem) {
      // call updateData function from the caller component
      updateData(updatedId, updatedItem);

      // clear the states
      setUpdatedId(null);
      setUpdatedItem(null);
    }
  }, [updatedId, updatedItem, updateData]);

  const isEditing = (record) => {
    return record.id === editableRowKey;
  };

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditableRowKey(record.id);
  };

  const cancel = () => {
    setEditableRowKey("");
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const index = data.findIndex((item) => id === item.id);

      if (index > -1) {
        setUpdatedId(id);
        setUpdatedItem(row);
        // const item = data[index];
        // setUpdatedItem({...item, ...row})
        // newData.splice(index, 1, {...item, ...row});
        // setData(newData);
        setEditableRowKey("");
      } else {
        console.error(`Invalid Index of row`);
        // newData.push(row);
        // setData(newData);
        // setEditableRowKey('');
      }
    } catch (error) {
      console.log("Validate Failed:", error);
    }
  };

  const columns = [
    ...originalColumns,
    {
      title: <span className="text-[#CE257A] ">Operation</span>,
      dataIndex: "operation",
      key: "operation",
      fixed: "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="space-y-4">
            <Button
              onClick={() => save(record.id)}
              className="bg-[#CE257A] text-white hover:bg-brown-500 transition-colors duration-300"
            >
              Save
            </Button>
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={cancel}
              okButtonProps={{
                style: { backgroundColor: "#CE257A", borderColor: "#CE257A" },
              }}
              cancelButtonProps={{
                style: {
                  backgroundColor: "#CE257A",
                  borderColor: "#CE257A",
                  color: "white",
                },
              }}
            >
              <Button className="bg-[#CE257A] text-white hover:bg-brown-500 transition-colors duration-300">
                Cancel
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editableRowKey !== ""}
            onClick={() => edit(record)}
            className="text-[#CE257A] font-sora"
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "yearOfBirth" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        key: col.key,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
        rowKey="id"
        expandable={expandable}
        scroll={scroll}
      />
    </Form>
  );
};

export default EditableTable;
