import React, { useState, useContext } from "react";
import { LanguageContext } from "../Language/LanguageContext";
import ContactImage from "../../assets/contact.png";
import BkgImage from "../../assets/bkg.png";
import { languageContent } from "../Language/languageContent";
import { Button } from "antd";
import "../../index.css";
function Contact() {
  const [isBouncing, setIsBouncing] = useState(false);
  const { language } = useContext(LanguageContext);
  //Language
  const { help, helpMessage, contact } = languageContent[language];
  return (
    <div
      name="contact"
      className="w-full bg-white py-25 px-4 max-h-[680px] mx-auto "
      style={{
        backgroundImage: `url(${BkgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minHeight: "50vh",
      }}
    >
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 items-center">
        <img
          className={`w-[500px] mx-auto my-4 ${
            isBouncing ? "animate-bounce" : ""
          }`}
          src={ContactImage}
          alt="/"
          onClick={() => {
            setIsBouncing(true);
            setTimeout(() => setIsBouncing(false), 3000);
          }}
        />

        <div className="flex flex-col justify-center items-center md:items-start sora">
          <h1 className="text-[rgb(223,0,112)] md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            {help}
          </h1>
          <p>{helpMessage}</p>
          <a href="https://mogzit.com/contact/">
            <Button
              type="primary"
              size="large"
              className="pink-button rounded-md sora font-medium my-6 mx-auto md:mx-0 py-3  w-[200px]"
            >
              {contact}
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
