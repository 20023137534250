// Allowed rule types are
// export declare type RuleType = 'string' | 'number' | 'boolean' | 'method' | 'regexp' | 'integer' | 'float' | 'object' | 'enum' | 'date' | 'url' | 'hex' | 'email';
export const addServiceProviderFormValues = () => [
  {
    label: "First Name",
    name: "firstName",
    placeholder: "",
    type: "text",
    rules: [{ required: true }],
  },
  {
    label: "Middle Name",
    name: "middleName",
    placeholder: "",
    type: "text",
    rules: [{ required: true }],
  },
  {
    label: "Last Name",
    name: "lastName",
    placeholder: "",
    type: "text",
    rules: [{ required: true }],
  },
  {
    label: "Phone number",
    name: "phoneNumber",
    placeholder: "Phone number",
    type: "tel",
    rules: [{ required: true }],
  },
  {
    label: (
      <span
        style={{
          color: "#CE257A",
          fontFamily: "Sora",
          maxWidth: "200px",
          display: "inline-block",
        }}
      >
        Alternative Phone
        <br />
        number
      </span>
    ),
    name: "alternativePhoneNumber",
    placeholder:
      "If we cannot reach you with your main number, we will call this number.",
    type: "tel",
    rules: [{}],
    enabledForTypeOfService: ["cookCleaner", "homeTutor"],
  },
  {
    label: "Email",
    name: "email",
    placeholder: "Write your correct email including @ and dot (.)",
    type: "email",
    rules: [{ type: "email" }],
  },
  {
    label: "Year of Birth",
    name: "yearOfBirth",
    placeholder: "",
    type: "date",
    rules: [{ type: "date", required: true }],
    maxDate: "2011-01-01",
    // either one (minDate vs maxDate) should be included.
    // MinDate is disabled until a need is there.
    // "minDate": "2011-01-01",
  },
  {
    label: "Is Certified?",
    name: "isCertified",
    placeholder: "",
    type: "radio",
    rules: [{ type: "integer", required: true }], // because the value is of type number
    enabledForTypeOfService: ["nanny"],
    values: [
      { value: 1, label: "Yes" },
      { value: 2, label: "No" },
    ],
  },
  {
    label: "Type Of Service",
    name: "typeOfService",
    placeholder: "",
    type: "radio",
    rules: [{ required: true }],
    enabledForTypeOfService: ["nanny"],
    values: [
      { value: "LIVING_IN", label: "Living In" },
      { value: "PART_TIME", label: "Part Time" },
      { value: "ON_DEMAND", label: "On Demand" },
      { value: "NIGHT_NANNY", label: "Night Nanny" },
    ],
  },
  {
    label: "Type Of Service",
    name: "typeOfService",
    placeholder: "",
    type: "radio",
    rules: [{ required: true }],
    enabledForTypeOfService: ["cookCleaner"],
    values: [
      { value: "COOK", label: "Cook" },
      { value: "CLEANER", label: "Cleaner" },
      { value: "COOK_CLEANER", label: "Cook Cleaner" },
    ],
  },
  {
    label: "Year of Experience",
    name: "yearOfExperience",
    placeholder: "",
    type: "number",
    rules: [{ type: "number", min: 0, max: 20 }],
    enabledForTypeOfService: ["cookCleaner", "homeTutor"],
  },
  {
    label: "Major",
    name: "major",
    placeholder: "Major subject of study",
    type: "text",
    rules: [{ required: true }],
    enabledForTypeOfService: ["homeTutor"],
  },
  {
    label: "Education",
    name: "education",
    placeholder: "Diploma, Bachelors, Masters, PhD",
    type: "text",
    rules: [{ required: true }],
    enabledForTypeOfService: ["homeTutor"],
  },
  {
    label: "Age Groups",
    name: "ageGroups",
    placeholder: "Click here to see Age Groups",
    type: "select",
    rules: [{ type: "array" }],
    enabledForTypeOfService: ["nanny"],
    values: [
      { value: "NEW_BORN", label: "New Born" },
      { value: "TODDLER", label: "Toddler" },
      { value: "SCHOOL_AGE", label: "School Age" },
      { value: "ALL_AGES", label: "All Ages" },
    ],
  },
  {
    label: "About",
    name: "aboutMe",
    placeholder: "",
    type: "textArea",
    rules: [{}],
    enabledForTypeOfService: ["nanny", "homeTutor"],
  },
  {
    label: "Address",
    name: "address",
    placeholder: "",
    type: "text",
    rules: [{ required: true }],
  },
  {
    label: "Place to Work",
    name: "place",
    placeholder: "",
    type: "text",
    rules: [{ required: true }],
  },
  {
    label: "Languages",
    name: "languages",
    placeholder: "",
    type: "text",
    rules: [{ required: true }],
  },
  {
    label: (
      <span
        style={{
          color: "#CE257A",
          fontFamily: "Sora",
          maxWidth: "200px",
          display: "inline-block",
        }}
      >
        Date/Time
        <br /> Preference
      </span>
    ),
    name: "dateTimePreference",
    placeholder: "",
    type: "text",
    rules: [{}],
  },
  {
    label: (
      <span
        style={{
          color: "#CE257A",
          fontFamily: "Sora",
          maxWidth: "200px",
          display: "inline-block",
        }}
      >
        Price Per Month <br />
        (in Birr)
      </span>
    ),
    name: "pricePerMonth", //TODO: use common 'price' field
    placeholder: "",
    type: "number",
    rules: [{ type: "number", min: 100, max: 10000, required: true }],
    enabledForTypeOfService: ["nanny", "cookCleaner"],
  },
  {
    label: (
      <span
        style={{
          color: "#CE257A",
          fontFamily: "Sora",
          maxWidth: "200px",
          display: "inline-block",
        }}
      >
        Price Per Hour <br />
        (in Birr)
      </span>
    ),

    name: "pricePerMonth", //TODO: use common 'price' field
    placeholder: "",
    type: "number",
    rules: [{ type: "number", min: 100, max: 10000, required: true }],
    enabledForTypeOfService: ["homeTutor"],
  },
  {
    label: (
      <span
        style={{
          color: "#CE257A",
          fontFamily: "Sora",
          maxWidth: "200px",
          display: "inline-block",
        }}
      >
        What can you cook <br />
        for Breakfast?
      </span>
    ),
    name: "breakfast",
    placeholder: "",
    type: "textArea",
    rules: [{}],
    enabledForTypeOfService: ["cookCleaner"],
  },
  {
    label: (
      <span
        style={{
          color: "#CE257A",
          fontFamily: "Sora",
          maxWidth: "200px",
          display: "inline-block",
          marginTop: "23px",
        }}
      >
        What can you cook <br />
        for Lunch <br /> and Dinner?
      </span>
    ),
    name: "lunchDinner",
    placeholder: "",
    type: "textArea",
    rules: [{}],
    enabledForTypeOfService: ["cookCleaner"],
  },
  {
    label: (
      <span
        style={{
          color: "#CE257A",
          fontFamily: "Sora",
          maxWidth: "200px",
          display: "inline-block",
          marginTop: "23px",
        }}
      >
        What can you cook <br />
        for Habesha
        <br /> Breakfast?
      </span>
    ),
    name: "habeshaBreakfast",
    placeholder: "",
    type: "textArea",
    rules: [{}],
    enabledForTypeOfService: ["cookCleaner"],
  },
  {
    label: (
      <span
        style={{
          color: "#CE257A",
          fontFamily: "Sora",
          maxWidth: "200px",
          display: "inline-block",
          marginTop: "23px",
        }}
      >
        What can you cook <br />
        for Habesha
        <br /> Lunch and Dinner?
      </span>
    ),
    name: "habeshaLunchDinner",
    placeholder: "",
    type: "textArea",
    rules: [{}],
    enabledForTypeOfService: ["cookCleaner"],
  },
  {
    label: "Levels",
    name: "levels",
    placeholder: "Click here to see Levels",
    type: "select",
    rules: [{ type: "array" }],
    enabledForTypeOfService: ["homeTutor"],
    values: [
      { value: "ELEMENTARY", label: "Elementary" },
      { value: "MIDDLE_SCHOOL", label: "Middle School" },
      { value: "HIGH_SCHOOL", label: "High School" },
      { value: "PREPARATORY", label: "Preparatory" },
      { value: "COLLEGE", label: "College" },
      { value: "ADULT", label: "Adult" },
    ],
  },
  {
    label: "Subjects",
    name: "subjects",
    placeholder: "Click here to see Subjects",
    type: "select",
    rules: [{ type: "array" }],
    enabledForTypeOfService: ["homeTutor"],
    values: [
      { value: "ART", label: "Art" },
      { value: "ENGLISH", label: "English" },
      { value: "MATH", label: "Math" },
      { value: "LANGUAGES", label: "Languages" },
      { value: "TECHNOLOGY_AND_CODING", label: "Technology and Coding" },
      { value: "SCIENCE", label: "Science" },
      { value: "SOCIAL_SCIENCE", label: "Social Science" },
      { value: "SAT_TEST_PREP", label: "SAT Test Prep" },
      { value: "OTHER", label: "Other" },
    ],
  },
  {
    label: "Hobbies",
    name: "hobbies",
    placeholder: "",
    type: "textArea",
    rules: [{}],
    enabledForTypeOfService: ["homeTutor"],
  },
  // {
  //     "label": "Upload Profile Photo",
  //     "name": "uploadProfilePhoto",
  //     "placeholder": "",
  //     "type": "upload",
  //     "rules": [{}],
  // },
];
