/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from "react";
import { Form, Alert, Button, Modal, Row, Spin, Upload, message } from "antd";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  GetFormInputByType,
  getTextOfServiceProvider,
  validateFormInput,
} from "../../../utils/helpers";
import {
  dummyRequest,
  handleImageChange,
  validateAndUpload,
} from "../../../utils/uploadImageToCloudinary";
import { addServiceProviderFormValues } from "./addServiceProviderFormValues";
import "./AdminAddServiceProvider.css";
import { layout } from "./AdminAddServiceProvider.style";
import { ServiceProviderAPI } from "../../../apis/serviceProviderAPI";
import { useNavigate, useParams } from "react-router";

const AdminAddServiceProvider = (props) => {
  
  const { typeOfServiceProvider } = useParams(); 

  const text = getTextOfServiceProvider(typeOfServiceProvider);
  const navigate = useNavigate();
  
  const [serviceProviderInfo, setServiceProviderInfo] = useState([]);

  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");
  const [imageLoading, setImageLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [isFormReadyToSubmit, setIsFormReadyToSubmit] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);

  useEffect(() => {
    const handleAfterSaving = (response) => {
      message.success({
        content: `${text.serviceNamePascalCase} is successfully registered with id ${response.id}.`,
        duration: 10,
      });
      navigate(`/${typeOfServiceProvider}`);
    };

    if (isFormReadyToSubmit) {
      ServiceProviderAPI.create(typeOfServiceProvider, serviceProviderInfo)
        .then((response) => {
          handleAfterSaving(response);
        })
        .catch((error) => {
          setErrorMessage(error);
        })
        .finally(() => {
          setIsSpinnerVisible(false);
          setIsFormReadyToSubmit(false);
        });
    }
  }, [
    isFormReadyToSubmit,
    navigate,
    typeOfServiceProvider,
    serviceProviderInfo,
    text.serviceNamePascalCase,
  ]);

  const handleOk = () => {
    setIsFormReadyToSubmit(true);
    setIsModalVisible(false);
    setIsSpinnerVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    // Should format date value before submit.
    setServiceProviderInfo({
      ...values.serviceProviderInfo,
      yearOfBirth: values.serviceProviderInfo.yearOfBirth.format("YYYY"),
      profilePhotoUrl: profilePhotoUrl,
    });
    setIsModalVisible(true);
  };

  const onImageChange = (info) => {
    setImageLoading(handleImageChange(info));
  };

  const onUploadImage = async (file) => {
    const { secure_url, error } = await validateAndUpload(
      file,
      typeOfServiceProvider
    );
    if (error) {
      message.error({ content: error, duration: 5 });
      return;
    }

    setProfilePhotoUrl(secure_url);
  };

  const uploadButton = (
    <div>
      {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Title
        level={3}
        style={{
          marginTop: "10px",
          marginBottom: "30px",
          color: "#CE257A",
          fontFamily: "Sora",
        }}
      >
        {text.addViewText}
      </Title>

      {errorMessage && (
        <Alert
          message={`Adding ${typeOfServiceProvider} error ${errorMessage}`}
          type="error"
        />
      )}

      <Row type="flex" align="center" style={{ margin: "20px" }}>
        {isSpinnerVisible && <Spin />}
        <Paragraph
          style={{ color: "#CE257A", fontFamily: "Sora", fontSize: "18px" }}
        >
          This is the place to add new {text.serviceName} to the system.
        </Paragraph>
      </Row>

      <Form
        {...layout}
        name="booking-service-provider-form"
        onFinish={onFinish}
        validateMessages={validateFormInput}
      >
        <Form.Item
          label={
            <span style={{ color: "#CE257A", fontFamily: "Sora" }}>
              Upload profile photo
            </span>
          }
        >
          <Upload
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            // action={uploadImage} // this code will not upload it. Don't uncomment.
            beforeUpload={onUploadImage}
            onChange={onImageChange}
            customRequest={dummyRequest}
          >
            {profilePhotoUrl ? (
              <img
                src={profilePhotoUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        {addServiceProviderFormValues().map((item) => (
          <div className="mb-6">
            {" "}
            <GetFormInputByType
              item={item}
              key={item.name + item.enabledForTypeOfService}
              typeOfServiceProvider={typeOfServiceProvider}
              formName={"serviceProviderInfo"}
            />
          </div>
        ))}

        <Form.Item
          style={{
            wrapperCol:
              window.innerWidth <= 768
                ? { span: 24 }
                : { ...layout.wrapperCol, offset: 8 },
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            className="pink-button w-[200px] rounded-md font-medium my-3 pb-2 sora"
          >
            Add {text.serviceNamePascalCase}
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title="Confirmation"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ color: "#CE257A", fontFamily: "Sora" }}
        okButtonProps={{
          className:
            "bg-[#CE257A] text-white hover:bg-brown-500 transition-colors duration-300",
        }}
        cancelButtonProps={{
          className:
            "bg-[#CE257A] text-white hover:bg-brown-500 transition-colors duration-300",
        }}
      >
        <p>Are you sure you have fully completed the required fields?</p>
      </Modal>
    </>
  );
};

export default AdminAddServiceProvider;
