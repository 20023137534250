import { Link } from "react-router-dom";
import { Image } from "antd";

// import React from "react";
// import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
//import { Button, Image, Input, Space, Switch, Tag } from "antd";

// const filterDropDown = ({
//   setSelectedKeys,
//   selectedKeys,
//   confirm,
//   clearFilters,
// }) => {
//   return (
//     <div className="p-4 space-y-4 font-sora">
//       <Input
//         autoFocus
//         placeholder="enter text to search"
//         value={selectedKeys[0]}
//         onChange={(e) => {
//           setSelectedKeys(e.target.value ? [e.target.value] : []);
//           // search with typing (auto-search) but do not close the search bar afterwards
//           //confirm({ closeDropdown: false});
//         }}
//         onPressEnter={() => {
//           confirm();
//         }}
//         onBlur={() => {
//           confirm();
//         }}
//       />
//       <div className="space-x-4">
//         <Button
//           type="primary"
//           onClick={() => {
//             confirm();
//           }}
//           className="bg-[#CE257A] text-white hover:bg-brown-500 transition-colors duration-300"
//         >
//           Search
//         </Button>
//         <Button
//           type="danger"
//           onClick={() => {
//             clearFilters();
//           }}
//           className="bg-red-500 text-white hover:bg-red-700 transition-colors duration-300"
//         >
//           Reset
//         </Button>
//       </div>
//     </div>
//   );
// };

// const filterIcon = (filtered) => {
//   return !filtered ? <SearchOutlined /> : <FilterOutlined />;
// };

// export const listColumns = (
//   typeOfServiceProvider,
//   handleUpdatedFlag,
//   handleDeleteClick
// ) => [
//   {
//     title: <span className="text-[#CE257A] font-sora">ID</span>,
//     dataIndex: "id",
//     key: "id",
//     width: "4%",
//     fixed: "left",
//     render: (id) => <Link to={`/${typeOfServiceProvider}/${id}`}>{id}</Link>,
//   },
//   {
//     title: <span className="text-[#CE257A] font-sora">Profile Photo</span>,
//     dataIndex: "profilePhotoUrl",
//     key: "profilePhotoUrl",
//     fixed: "left",
//     render: (profilePhotoUrl) => (
//       <Image src={profilePhotoUrl} width="50px" alt="Profile" />
//     ),
//   },
//   {
//     title: <span className="text-[#CE257A] font-sora">First Name</span>,
//     dataIndex: "firstName",
//     key: "firstName",
//     fixed: "left",
//     editable: true,
//     filterIcon: filterIcon(),
//     filterDropdown: filterDropDown,
//     onFilter: (value, record) => {
//       return record.firstName.toLowerCase().includes(value.toLowerCase());
//     },
//     render: (text) => <span className="font-sora">{text}</span>,
//   },
//   {
//     title: <span className="text-[#CE257A] font-sora">Middle Name</span>,
//     dataIndex: "middleName",
//     key: "middleName",
//     editable: true,
//     render: (text) => <span className="font-sora">{text}</span>,
//   },
//   {
//     title: <span className="text-[#CE257A] font-sora">Last Name</span>,
//     dataIndex: "lastName",
//     key: "lastName",
//     editable: true,
//     render: (text) => <span className="font-sora">{text}</span>,
//   },
//   {
//     title: <span className="text-[#CE257A] font-sora">Phone Number</span>,
//     dataIndex: "phoneNumber",
//     key: "phoneNumber",
//     editable: true,
//     render: (text) => <span className="font-sora">{text}</span>,
//   },
//   {
//     title: <span className="text-[#CE257A] font-sora">Price Per Month</span>,
//     dataIndex: "pricePerMonth",
//     key: "pricePerMonth",
//     render: (pricePerMonth) => {
//       let color = pricePerMonth > 5000 ? "geekblue" : "#CE257A";
//       if (pricePerMonth === 0) color = "red";
//       return (
//         <Tag color={color} key={pricePerMonth}>
//           {pricePerMonth}
//         </Tag>
//       );
//     },
//     editable: true,
//   },
//   {
//     title: <span className="text-[#CE257A] font-sora">Place</span>,
//     dataIndex: "place",
//     key: "place",
//     editable: true,
//     filterIcon: filterIcon(),
//     filterDropdown: filterDropDown,
//     onFilter: (value, record) => {
//       return record.place.toLowerCase().includes(value.toLowerCase());
//     },
//     render: (text) => <span className="font-sora">{text}</span>,
//   },
//   {
//     title: <span className="text-[#CE257A] font-sora">Email</span>,
//     dataIndex: "email",
//     key: "email",
//     editable: false,
//     render: (text) => <span className="font-sora">{text}</span>,
//   },
//   {
//     title: <span className="text-[#CE257A] font-sora">Type of Service</span>,
//     dataIndex: "typeOfService",
//     key: "typeOfService",
//     editable: false,
//     filterIcon: filterIcon(),
//     filterDropdown: filterDropDown,
//     onFilter: (value, record) => {
//       return record.typeOfService.toLowerCase().includes(value.toLowerCase());
//     },
//     render: (text) => <span className="font-sora">{text}</span>,
//   },
//   // {
//   //     title: 'Year of Birth',
//   //     dataIndex: 'yearOfBirth',
//   //     key: 'yearOfBirth',
//   // },
//   // {
//   //     title: 'Languages',
//   //     dataIndex: 'languages',
//   //     key: 'languages',
//   // },
//   // {
//   //     title: 'Age Groups',
//   //     dataIndex: 'ageGroups',
//   //     key: 'ageGroups',
//   // },
//   {
//     title: <span className="text-[#CE257A] font-sora">Certified</span>,
//     dataIndex: "isCertified",
//     key: "isCertified",
//     render: (isCertified, record) => (
//       <Switch
//         checked={isCertified}
//         onChange={(value) => {
//           handleUpdatedFlag("isCertified", record.id, value);
//         }}
//         className="bg-[#CE257A] text-white hover:bg-brown-500 transition-colors duration-300"
//       />
//     ),
//     editable: false,
//   },

//   {
//     title: <span className="text-[#CE257A] font-sora">Active</span>,
//     dataIndex: "isActive",
//     key: "isActive",
//     render: (isActive, record) => (
//       <Switch
//         checked={isActive}
//         onChange={(value) => {
//           handleUpdatedFlag("isActive", record.id, value);
//         }}
//         className="bg-[#CE257A] text-white hover:bg-brown-500 transition-colors duration-300"
//       />
//     ),
//     editable: false,
//   },
//   {
//     title: <span className="text-[#CE257A] font-sora">Booked</span>,
//     dataIndex: "isBooked",
//     key: "isBooked",
//     render: (isBooked, record) => (
//       <Switch
//         checked={isBooked}
//         onChange={(value) => {
//           handleUpdatedFlag("isBooked", record.id, value);
//         }}
//         className="bg-[#CE257A] text-white hover:bg-brown-500 transition-colors duration-300"
//       />
//     ),
//   },

//   {
//     //TODO: can be merged with Operations
//     title: <span className="text-[#CE257A] font-sora">Actions</span>,
//     dataIndex: "actions",
//     key: "actions",
//     fixed: "right",
//     render: (text, record) => (
//       <Space size="middle">
//         <Button
//           danger
//           onClick={() => handleDeleteClick(record.id)}
//           style={{
//             backgroundColor: "red",
//             color: "white",
//             transition: "background-color 0.3s ease",
//             fontFamily: "sora",
//           }}
//           onMouseEnter={(e) =>
//             (e.currentTarget.style.backgroundColor = "darkred")
//           }
//           onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "red")}
//         >
//           Delete
//         </Button>
//       </Space>
//     ),
//   },
// ];
export const searchListColumns = () => [
  {
    title: <span className="text-[#CE257A] font-sora">ID</span>,
    dataIndex: "id",
    key: "id",
    width: "4%",
    fixed: "left",
    render: (id) => <Link to={`/serviceProvider/${id}`}>{id}</Link>,
  },
  {
    title: <span className="text-[#CE257A] font-sora">Profile Photo</span>,
    dataIndex: "profilePhotoUrl",
    key: "profilePhotoUrl",
    fixed: "left",
    render: (profilePhotoUrl) => (
      <Image src={profilePhotoUrl} width="50px" alt="Profile" />
    ),
  },
  {
    title: <span className="text-[#CE257A] font-sora">First Name</span>,
    dataIndex: "firstName",
    key: "firstName",
    fixed: "left",
    render: (text) => <span className="font-sora">{text}</span>,
  },
  {
    title: <span className="text-[#CE257A] font-sora">Middle Name</span>,
    dataIndex: "middleName",
    key: "middleName",
    render: (text) => <span className="font-sora">{text}</span>,
  },
  // ... other columns ...
];
