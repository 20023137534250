import { useContext } from "react";
import { languageContent } from "../../../components/Language/languageContent";
import { LanguageContext } from "../../../components/Language/LanguageContext";
export const useBookingFormValues = () => {
  const { language } = useContext(LanguageContext);
  const {
    firstName,
    firstNameMessage,
    middleName,
    middleNameMessage,
    lastName,
    lastNameMessage,
    phoneNumber,
    phoneNumberMessage,
    alternativePhoneNumber,
    alternativePhoneNumberMessage,
    alternativePhonePlaceHolder,
    email,
    emailMessage,
    placeOfResidence,
    placeOfResidenceMessage,
    placeOfResidencePlaceHolder,
    contactPreference,
    contactPreferenceMessage,
    contactPreferencePlaceHolder,
    clientMessageLabel,
    clientMessageInfo,
    clientMessageInfoPlaceHolder,
  } = languageContent[language];

  return [
    {
      label: firstName,
      name: "firstName",
      placeholder: "",
      type: "text",
      rules: [{ required: true, message: firstNameMessage }],
    },
    {
      label: middleName,
      name: "middleName",
      placeholder: "",
      type: "text",
      rules: [{ required: true, message: middleNameMessage }],
    },
    {
      label: lastName,
      name: "lastName",
      placeholder: "",
      type: "text",
      rules: [{ required: true, message: lastNameMessage }],
    },
    {
      label: phoneNumber,
      name: "phoneNumber",
      placeholder: phoneNumber,
      type: "tel",
      rules: [{ required: true, message: phoneNumberMessage }],
    },
    {
      label: alternativePhoneNumber,
      name: "alternativePhoneNumber",
      placeholder: alternativePhonePlaceHolder,
      type: "tel",
      rules: [{ required: true, message: alternativePhoneNumberMessage }],
    },
    {
      label: email,
      name: "email",
      placeholder: emailMessage,
      type: "email",
      rules: [{ type: "email" }],
    },
    {
      label: "Number of Children",
      name: "numberOfChildren",
      placeholder: "",
      type: "number",
      rules: [{ type: "number", min: 0, max: 99 }],
      enabledForTypeOfService: ["nanny"],
    },
    {
      label: placeOfResidence,
      name: "placeOfResidence",
      placeholder: placeOfResidencePlaceHolder,
      type: "text",
      rules: [{ required: true, message: placeOfResidenceMessage }],
    },
    {
      label: contactPreference,
      name: "contactPreference",
      placeholder: contactPreferencePlaceHolder,
      type: "text",
      rules: [{ required: true, message: contactPreferenceMessage }],
    },
    {
      label: clientMessageLabel,
      name: "message",
      placeholder: clientMessageInfoPlaceHolder,
      type: "textArea",
      rules: [{ required: true, message: clientMessageInfo }],
      // "rules": [{}],
    },
  ];
};
