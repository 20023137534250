/* eslint-disable no-template-curly-in-string */
import React, { useContext, useState, useEffect, Fragment } from "react";
import { useParams, useNavigate, Link } from "react-router-dom"; // > V5.1
import {
  Form,
  Alert,
  Button,
  Breadcrumb,
  Modal,
  Row,
  Progress,
  Spin,
  message,
  Checkbox,
} from "antd";
import Title from "antd/es/typography/Title";
import { languageContent } from "../../../components/Language/languageContent";
import { LanguageContext } from "../../../components/Language/LanguageContext";
import BkgImage from "../../../assets/bkg.png";
import { layout } from "./BookServiceProvider.style.js";
import {
  getBookingTypeByServiceProvider,
  GetFormInputByType,
  getTextOfServiceProvider,
} from "../../../utils/helpers";
import { useBookingFormValues } from "./bookingFormValues";
import { ServiceProviderAPI } from "../../../apis/serviceProviderAPI";
import { BookingAPI } from "../../../apis/bookingAPI";
import { CheckCircleOutlined } from "@ant-design/icons";
import TermsAndCondtionModal from "../../../components/Modals/TermsAndCondtionModal.jsx";
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const BookServiceProvider = () => {
  // Scroll to the application section when the component is rendered
  useEffect(() => {
    document
      .getElementById("application-section")
      .scrollIntoView({ behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const { id, typeOfServiceProvider } = useParams();

  const [serviceProvider, setServiceProvider] = useState(undefined);
  const [bookingInfo, setBookingInfo] = useState();

  const [errorMessage, setErrorMessage] = useState("");

  const [isFormReadyToSubmit, setIsFormReadyToSubmit] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);

  const bookingType = getBookingTypeByServiceProvider(typeOfServiceProvider);
  const text = getTextOfServiceProvider(typeOfServiceProvider);
  //Language
  const { language } = useContext(LanguageContext);
  const {
    book,
    homeTutorsList,
    nanniesList,
    homeTutorInfo,
    nannyInfo,
    brHour,
    brMonth,
    bookingInformation,
    bookingInformationTwo,
    bookingInformationThree,
    alertContentOne,
    alertContentTwo,
    alertContentThree,
    alertContentFour,
    alertContentFive,
    alertContentSix,
    alertContentSeven,
    alertContentEight,
    alertContentNine,
    homeTutor,
    nanny,
    priceInfo,
    bookNanny,
    bookHomeTutor,

    agreement,
    agreementPlaceHolder,
    agreementMessageOne,
    agreementMessageTwo,
    bookingConfrimation, // eslint-disable-line no-unused-vars
    bookingConfirmationQuestion,
    Confirmation,
  } = languageContent[language];
  useEffect(() => {
    ServiceProviderAPI.get(typeOfServiceProvider, id)
      .then((response) => {
        setServiceProvider(response);
      })
      .catch((error) => {
        console.error(error);
        setServiceProvider("");
      });
  }, [id, typeOfServiceProvider]);

  useEffect(() => {
    const handleAfterSaving = (response) => {
      message.success({
        content: `Your booking is successfully registered with booking id ${response.id}.`,
        duration: 10,
      });
      navigate(`/${typeOfServiceProvider}`);
      setIsSpinnerVisible(false);
      setIsFormReadyToSubmit(false);
    };

    if (isFormReadyToSubmit) {
      BookingAPI.create({
        bookingType: bookingType,
        bookedItemId: id,
        ...bookingInfo,
      })
        .then((response) => {
          handleAfterSaving(response);
        })
        .catch((error) => {
          setErrorMessage(error);
          setIsFormReadyToSubmit(false);
        });
    }
  }, [
    isFormReadyToSubmit,
    id,
    navigate,
    typeOfServiceProvider,
    bookingInfo,
    bookingType,
  ]);

  const handleOk = () => {
    setIsFormReadyToSubmit(true);
    setIsModalVisible(false);
    setIsSpinnerVisible(true);
    // setTimeout(() => {
    //   navigate("/thankyou");
    // }, 400); //
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    setBookingInfo(values.bookingInfo);
    setIsModalVisible(true);
  };

  // const onReset = () => {
  //     form.resetFields();
  // };

  return (
    <>
      <main
        class="ant-layout-content bg-white"
        id="application-section"
        style={{
          padding: 50,
          backgroundImage: `url(${BkgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          minHeight: "100vh", // Ensure the div takes up the full height of the viewport
        }}
      >
        <div class="form-layout-content">
          {serviceProvider !== undefined && serviceProvider && (
            <Breadcrumb>
              <Breadcrumb.Item key="home">Mogzit.com</Breadcrumb.Item>
              <Breadcrumb.Item key="list">
                <Link to="/">
                  {text.listViewText === "List Nannies"
                    ? nanniesList
                    : homeTutorsList}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item key="detail">
                <Link to={`/serviceProvider/${serviceProvider.id}`}>
                  {text.detailViewText === "Nanny Info"
                    ? nannyInfo
                    : homeTutorInfo}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item key="book">
                <Link
                  to={`/book/${typeOfServiceProvider}/${serviceProvider.id}`}
                >
                  <span className="text-[#c90254] font-bold">
                    {text.bookLabel === "Book Nanny"
                      ? bookNanny
                      : bookHomeTutor}
                  </span>
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          )}

          <Title
            level={3}
            style={{
              marginTop: "10px",
              color: "#c90254",
              fontFamily: "Sora",
            }}
          >
            {text.title === "Nanny Info" ? nannyInfo : homeTutorInfo}
          </Title>

          <Row
            type="flex"
            align="center"
            style={{ margin: "20px", gap: "10px" }}
          >
            <Alert
              message={
                <span
                  style={{
                    color: "#CE257A",
                    fontSize: "16px",
                    fontFamily: "Sora, sans-serif",
                    fontWeight: "bolder",
                  }}
                >
                  {priceInfo}
                </span>
              }
              description={
                <Fragment>
                  <div>
                    {alertContentOne}
                    <br />
                    {alertContentTwo}{" "}
                    {typeOfServiceProvider === "homeTutor" ? homeTutor : nanny},{" "}
                    {alertContentThree}
                    <br />
                    {alertContentFour} <strong>{alertContentFive}</strong>{" "}
                    {alertContentSix}{" "}
                    {typeOfServiceProvider === "homeTutor" ? homeTutor : nanny}
                    {alertContentSeven} <strong>30%</strong> {alertContentEight}
                    {typeOfServiceProvider === "homeTutor"
                      ? homeTutor
                      : nanny}{" "}
                    {alertContentNine}
                    <br />
                  </div>
                </Fragment>
              }
              //   type="success"
              showIcon
              style={{
                backgroundColor: "rgb(206,37,122,0.3)",
                color: "#ffffff",
                fontFamily: "Sora, sans-serif",
                fontSize: "14px",
              }}
              icon={<CheckCircleOutlined style={{ color: "#CE257A" }} />}
            />
          </Row>
          {errorMessage && (
            <Alert message="Booking error ${errorMessage}" type="error" />
          )}

          <Row type="flex" align="center" style={{ margin: "20px" }}>
            {serviceProvider === undefined && <Progress type="circle" />}
            {isSpinnerVisible && <Spin />}
            {serviceProvider && (
              <div className="ant-typography sora">
                {bookingInformation}{" "}
                {typeOfServiceProvider === "homeTutor" ? homeTutor : nanny}{" "}
                {bookingInformationTwo}{" "}
                <b>
                  {serviceProvider.firstName} {serviceProvider.middleName}
                </b>{" "}
                {bookingInformationThree} {serviceProvider.pricePerMonth}{" "}
                {typeOfServiceProvider === "homeTutor" ? brHour : brMonth}
              </div>
            )}
          </Row>
          <div style={{ width: "100%", overflowX: "hidden" }}>
            <Form
              {...layout}
              name="booking-service-provider-form"
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              {useBookingFormValues().map((item) => (
                <GetFormInputByType
                  item={item}
                  key={item.name + item.enabledForTypeOfService}
                  typeOfServiceProvider={typeOfServiceProvider}
                  formName={"bookingInfo"}
                />
              ))}
              <Form.Item
                name="agreement"
                label={
                  <span style={{ color: "#CE257A" }} className="sora">
                    {agreement}
                  </span>
                }
                // wrapperCol={{ span: 16, offset: 8 }}
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(agreementPlaceHolder),
                  },
                ]}
              >
                <Checkbox className="pinkCheckbox sora">
                  {agreementMessageOne}{" "}
                  <a
                    href="https://mogzit.com/terms-and-conditions/"
                    className="terms"
                  >
                    {agreementMessageTwo}
                  </a>
                </Checkbox>
              </Form.Item>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="pink-button w-[200px] rounded-md font-medium my-6  sora"
                >
                  {book}{" "}
                  {text.serviceNamePascalCase === "Home Tutor"
                    ? homeTutor
                    : nanny}
                </Button>
              </Form.Item>
            </Form>
          </div>
          {serviceProvider && (
            <Modal
              title={
                <span style={{ color: "#CE257A", fontFamily: "Sora" }}>
                  {Confirmation}
                </span>
              }
              open={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              okButtonProps={{
                style: {
                  backgroundColor: "#CE257A",
                  color: "white",
                  ":hover": {
                    backgroundColor: "brown",
                  },
                },
              }}
              cancelButtonProps={{
                style: {
                  ":hover": {
                    backgroundColor: "brown",
                  },
                },
              }}
              bodyStyle={{ fontFamily: "Sora" }}
            >
              <p>
                {/* {bookingConfrimation} */}
                Are you sure you want to book {serviceProvider.firstName} as a{" "}
                {text.serviceName}?
              </p>
              <p>{bookingConfirmationQuestion}</p>
            </Modal>
          )}
          <TermsAndCondtionModal />
        </div>
        <hr
          style={{
            color: "#c90254",
            backgroundColor: "#c90254",
            border: "none",
            height: 3,
          }}
        />
      </main>
    </>
  );
};

export default BookServiceProvider;
