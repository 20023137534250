// Allowed rule types are
// export declare type RuleType = 'string' | 'number' | 'boolean' | 'method' | 'regexp' | 'integer' | 'float' | 'object' | 'enum' | 'date' | 'url' | 'hex' | 'email';
export const giveRatingAndReviewFormValues = () => [
    {
        "label": "Star rating",
        "name": "rating",
        "placeholder": "",
        "type": "rate",
        "rules": [{required: true}],
        "ratingDescription": ['terrible', 'bad', 'normal', 'good', 'wonderful'],
    },
    {
        "label": "Review",
        "name": "review",
        "placeholder": "Write a review about the service provider",
        "type": "textArea",
        "rules": [{required: true}],
        "rows": 5,
    },
]
