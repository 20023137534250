import axios from "axios";
import { API_BASE_URL } from "../../config";
import UserService from "../../services/UserService";

export const api = axios.create({
  baseURL: API_BASE_URL,
  // withCredentials: true,
});

api.interceptors.request.use((config) => {
  console.log(`API call intercepted`)
  if (UserService.isLoggedIn()) {
      const callback = () => {
          config.headers.Authorization = `Bearer ${UserService.getToken()}`;
          // config.headers["X-TENANT-ID"] = UserService.getRealm();
          return Promise.resolve(config);
      };
      return UserService.updateToken(callback);
  }
  return config; //https://stackoverflow.com/a/72589647
});

// defining a custom error handler for all APIs
const errorHandler = (error) => {
  const statusCode = error.response?.status;

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

// registering the custom error handler to the "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});
