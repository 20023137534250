import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  /*Avatar,*/ Button,
  Card,
  Col,
  Tooltip,
  Row,
  Alert,
  Rate,
  Pagination,
  Menu,
  Dropdown,
} from "antd";

import { CaretRightOutlined } from "@ant-design/icons";
import {
  constructFullName,
  getNameInitials,
  toFirstCharUpperCase,
} from "../../../utils/helpers";
import { Transformation } from "cloudinary-react";
import { Image as ImageCloudinary } from "cloudinary-react";
import { waterMarkIfBooked } from "../../../utils/watermark_image";
import { ServiceProviderAPI } from "../../../apis/serviceProviderAPI";
import BkgImage from "../../../assets/bkg.png";
import { languageContent } from "../../../components/Language/languageContent";
import { LanguageContext } from "../../../components/Language/LanguageContext";
import "../ListServiceProviders/Loader.css";
import { useNavigate, useParams } from "react-router-dom";

const ListServiceProviders = (props) => {
  // Scroll to the care giver section when the component is rendered
  useEffect(() => {
    document
      .getElementById("careGiver-section")
      .scrollIntoView({ behavior: "smooth" });
  }, []);

  console.log(`useParams()`, JSON.stringify(useParams()))
  const { typeOfServiceProvider } = useParams(); 
  const navigate = useNavigate();

  const { Meta } = Card;
  // Original list of service providers
  const [originalServiceProviderList, setOriginalServiceProviderList] =
    useState([]);
  // Filtered list of service providers
  const [serviceProviderList, setServiceProviderList] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [serviceProviderPerPage] = useState(12);
  
  const [totalServiceProviders, setTotalServiceProviders] = useState(0); // eslint-disable-line no-unused-vars
  
  //filters
  const [activeType, setActiveType] = useState("All");
  const [activePrice, setActivePrice] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  
  //Language
  const { language } = useContext(LanguageContext);
  const {
    homeTutorsList,
    nanniesList,
    filterByService,
    filterByWage,
    seeDetail,
    certified,
    notCertified,
    hour,
    month,
  } = languageContent[language];

  //Fetching Data
  useEffect(() => {
    setLoading(true);
    console.log(`ListServiceProviders.js`)
    ServiceProviderAPI.getAll(typeOfServiceProvider, true, true)
      .then((response) => {
        const newServiceProviderList = {};
        response.forEach((item, index) => {
          newServiceProviderList[index + 1] = {
            id: item.id,
            fullName: constructFullName(
              item.firstName,
              item.middleName,
              item.lastName
            ),
            name: `${toFirstCharUpperCase(item.firstName)}`,
            imageUrl: item.profilePhotoUrl,
            price: item.pricePerMonth,
            place: item.place,
            isCertified: item.isCertified,
            isBooked: item.isBooked,
            averageRating: item.averageRating,
            service: item.typeOfService,
          };
        });
        setOriginalServiceProviderList(newServiceProviderList); // Add this line
        setServiceProviderList(newServiceProviderList);
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
      });
  }, [typeOfServiceProvider]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  //filter functions
  // Remove the useCallback from filterType and filterPrice
  const filterType = (type, list) => {
    if (type === "All") {
      return list;
    } else {
      return Object.values(list).filter((provider) => {
        return provider.service === type;
      });
    }
  };

  const filterPrice = (priceRange, list) => {
    let minPrice, maxPrice;
    if (priceRange === "All") {
      return list;
    } else if (priceRange === "3k-4k") {
      minPrice = 3000;
      maxPrice = 4000;
    } else if (priceRange === "4k-6k") {
      minPrice = 4000;
      maxPrice = 6000;
    } else if (priceRange === "6k-10k") {
      minPrice = 6000;
      maxPrice = 10000;
    }

    return (
      Object.values(list).filter((provider) => {
        return provider.price >= minPrice && provider.price <= maxPrice;
      }) || []
    ); // Add this line
  };

  // Create a new function to apply both filters
  const applyFilters = useCallback(() => {
    let filteredList = originalServiceProviderList;
    filteredList = filterType(activeType, originalServiceProviderList); // Change this line
    filteredList = filterPrice(activePrice, filteredList);
    setServiceProviderList(filteredList);
  }, [originalServiceProviderList, activeType, activePrice]);

  // Call applyFilters in a useEffect hook
  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <Tooltip title="Show all available workers">
              <div
                onClick={() => {
                  setActiveType("All");
                }}
                style={{
                  color: activeType === "All" ? "#CE257A" : "black",
                  fontFamily: "Sora",
                }}
              >
                All
              </div>
            </Tooltip>
          ),
        },
        {
          key: "2",
          label: (
            <Tooltip title="Working Options: Monday - Friday 1:30 - 12:00 Local Time and Saturday - Half day">
              <div
                onClick={() => {
                  setActiveType("Part Time");
                }}
                style={{
                  color: activeType === "Part Time" ? "#CE257A" : "black",
                  fontFamily: "Sora",
                }}
              >
                Part Time
              </div>
            </Tooltip>
          ),
        },
        {
          key: "3",
          label: (
            <Tooltip title="Working Options: Flexible working hours">
              <div
                onClick={() => {
                  setActiveType("On Demand");
                }}
                style={{
                  color: activeType === "On Demand" ? "#CE257A" : "black",
                  fontFamily: "Sora",
                }}
              >
                On Demand
              </div>
            </Tooltip>
          ),
        },
      ]}
    />
  );

  const menuTwo = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div
              onClick={() => {
                setActivePrice("All");
              }}
              style={{
                color: activePrice === "All" ? "#CE257A" : "black",
                fontFamily: "Sora",
              }}
            >
              All
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              onClick={() => {
                setActivePrice("3k-4k");
              }}
              style={{
                color: activePrice === "3k-4k" ? "#CE257A" : "black",
                fontFamily: "Sora",
              }}
            >
              3k-4k
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <div
              onClick={() => {
                setActivePrice("4k-6k");
              }}
              style={{
                color: activePrice === "4k-6k" ? "#CE257A" : "black",
                fontFamily: "Sora",
              }}
            >
              4k-6k
            </div>
          ),
        },
        {
          key: "4",
          label: (
            <div
              onClick={() => {
                setActivePrice("6k-10k");
              }}
              style={{
                color: activePrice === "6k-10k" ? "#CE257A" : "black",
                fontFamily: "Sora",
              }}
            >
              6k-10k
            </div>
          ),
        },
      ]}
    />
  );

  const getCard = (serviceProvider) => {
    const {
      id,
      fullName,
      name,
      imageUrl,
      price,
      isCertified,
      isBooked,
      averageRating,
    } = serviceProvider;

    // source: https://stackoverflow.com/questions/71529154/extract-public-id-from-cloudinary-url
    // const imagePublicId = imageUrl.split("/").slice(7).join("/").split(".")[0];
    let imagePublicId = "";
    if (imageUrl) {
      imagePublicId = imageUrl.split("/").slice(7).join("/").split(".")[0];
    }

    // old code // const imagePublicId = imageUrl.split(/(https:\/\/res.cloudinary.com\/mogzit-com\/image\/upload\/v[0-9]*\/)([a-zA-Z0-9]*)(.jpg)/)[2]

    return (
      <Card
        style={{ width: "100%" }}
        className="hover-effect rounded-lg hover:scale-105 duration-300 "
        cover={
          // Transform the image to be shown in a square size
          imagePublicId ? (
            <ImageCloudinary
              cloudName="mogzit-com"
              secure="true"
              // upload_preset="profile-images"
              publicId={imagePublicId}
              className="w-[300px] h-[300px] object-cover rounded-t-lg"
            >
              <Transformation
                background="auto"
                gravity="auto"
                height="300"
                width="300"
                crop="fill_pad"
              />

              {/* Grayscale (Black/White) the image */}
              {/* <Transformation effect="grayscale" /> */}

              {/* Blur the original Image */}
              {isBooked && <Transformation effect="blur:100" />}

              {/* Add Reserved overlay image in south_east (gravity) with 100 blur, 20% width and 85% opacity */}
              {isBooked && (
                <Transformation
                  overlay="utils:reserved-sign_w0pncm.png"
                  width="0.20"
                  crop="scale"
                  opacity="85"
                  gravity="south_east"
                />
              )}
            </ImageCloudinary>
          ) : (
            waterMarkIfBooked(isBooked, imageUrl)
          )
        }
        actions={[
          <Button
            type="primary"
            icon={<CaretRightOutlined />}
            size={"large"}
            onClick={() => navigate(`/${typeOfServiceProvider}/${id}`)}
            disabled={isBooked}
            className="pink-button"
          >
            {seeDetail}
          </Button>,
        ]}
      >
        <Meta
          // avatar={<Avatar
          //     size={{xs: 5, sm: 10, md: 15, lg: 20, xl: 20, xxl: 30}}
          //     // src={"https://res.cloudinary.com/mogzit-com/image/upload/v1632474932/logo/mogzit_logo_xxowyl.png"}/>}
          //     src={"https://res.cloudinary.com/mogzit-com/image/upload/v1632475191/logo/cropped-logomogzit-32x32_z1e4gb.png"}/>}
          title={
            <>
              {getNameInitials(fullName)}
              {id}. {name}
            </>
          }
          description={
            <>
              <Rate
                disabled
                allowHalf
                value={averageRating}
                style={{ fontSize: 16 }}
              />
              <br />
              <strong className="text-grey">
                {typeOfServiceProvider === "nanny" &&
                  (isCertified === true ? certified : notCertified)}
              </strong>
              <p>
                {price} Birr/
                {typeOfServiceProvider === "homeTutor" ? hour : month}
              </p>
              {/*<strong>Location</strong>: {place.substring(0, 14)}...*/}
            </>
          }
        />
      </Card>
    );
  };
  // Calculate the current service providers to display
  const indexOfLastServiceProvider = currentPage * serviceProviderPerPage;
  const indexOfFirstServiceProvider =
    indexOfLastServiceProvider - serviceProviderPerPage;
    // eslint-disable-next-line no-unused-vars
  const currentServiceProviderKeys = Object.keys(serviceProviderList).slice(
    indexOfFirstServiceProvider,
    indexOfLastServiceProvider
  );
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);
  // Filter the service providers based on the search term
  const filteredServiceProviders = Object.values(serviceProviderList).filter(
    (serviceProvider) =>
      serviceProvider.place.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <>
      <div
        className="w-full py-[2rem] px-4 bg-white "
        id="careGiver-section"
        style={{
          backgroundImage: `url(${BkgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          minHeight: "100vh",
        }}
      >
        <h1 className="text-[#c90254] font-bold text-4xl text-center pb-10">
          {typeOfServiceProvider === "homeTutor" ? homeTutorsList : nanniesList}
        </h1>

        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by place..."
          className="w-50 px-3 py-2 placeholder-gray-500 text-gray-900 rounded-md border-2 border-gray-300 focus:outline-none focus:border-pink-600 mb-5"
        />

        {typeOfServiceProvider !== "homeTutor" && (
          <div className="flex flex-row justify-between mb-4">
            {/* Filter Type of Service */}
            <div className="mb-6 sm:mb-6 md:mb-6 lg:mb-6 sm:mr-4">
              <Dropdown menu={menu} placement="bottomLeft">
                <Button className="text-[#c90254] border-[#50464a] sora">
                  {filterByService}
                </Button>
              </Dropdown>
            </div>

            {/* Filter By Wage */}
            <div>
              <Dropdown menu={menuTwo} placement="bottomLeft">
                <Button className="text-[#c90254] border-[#c90254] sora">
                  {filterByWage}
                </Button>
              </Dropdown>
            </div>
          </div>
        )}
        {loading ? (
          <div className="loader">
            <div className="face">
              <div className="circle"></div>
              <div className="eye left"></div>
              <div className="eye right"></div>
            </div>
            <div className="shadow"></div>
          </div>
        ) : errorMessage ? (
          <Alert
            message={`Sorry an error occurred while trying to connect to API. Detail: ${errorMessage}`}
            type="error"
            style={{ backgroundColor: "#CE257A", color: "white" }}
          />
        ) : filteredServiceProviders.length === 0 && searchTerm ? (
          <p className="text-center   text-rose-600 mb-5 text-lg">
            There are no caregivers available at the location you have entered.
          </p>
        ) : (
          <>
            {searchTerm && (
              <p className="text-center   text-rose-600 mb-5 text-lg">
                There are {filteredServiceProviders.length} caregivers
                available.
              </p>
            )}
            <Row justify="space-around" align="middle" gutter={[32, 32]}>
              {filteredServiceProviders
                .slice(indexOfFirstServiceProvider, indexOfLastServiceProvider)
                .map((serviceProvider, index) => {
                  return (
                    <Col xs={24} sm={24} md={12} lg={8} xl={4} key={index}>
                      {getCard(serviceProvider)}
                    </Col>
                  );
                })}
            </Row>
          </>
        )}

        <div className="flex justify-center mt-4">
          <Pagination
            current={currentPage}
            total={filteredServiceProviders.length}
            pageSize={serviceProviderPerPage}
            onChange={paginate}
            showQuickJumper
            showSizeChanger={false}
            className="custom-pagination"
          />
        </div>
      </div>
    </>
  );
};

export default ListServiceProviders;
