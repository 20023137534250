import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const BookingAPI = {
    get: async function (bookingId, cancel = false) {
        const response = await api.request({
            url: `/booking/${bookingId}`,
            method: "GET",
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the booking returned by the API
        return response.data
    },
    getAll: async function (cancel = false) {
        const response = await api.request({
            url: "/booking",
            method: "GET",
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    delete: async function (bookingId, cancel = false) {
        await api.request({
            url: `/booking/${bookingId}`,
            method: "DELETE",
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
    },
    complete: async function (bookingId, toComplete, cancel = false) {
        await api.request({
            url: `/booking/${bookingId}/isCompleted?value=${toComplete}`,
            method: "PUT",
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
    },
    create: async function (booking, cancel = false) {
        const response = await api.request({
            url: `/booking`,
            method: "POST",
            data: booking,
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    // search: async function (name, cancel = false) {
    //     const response = await api.request({
    //         url: "/bookings/search",
    //         method: "GET",
    //         params: {
    //             name: name,
    //         },
    //         signal: cancel ? cancelApiObject[this.search.name].handleRequestCancellation().signal : undefined,
    //     })
    //
    //     return response.data
    // },
}

// defining the cancel API object for BookingAPI
const cancelApiObject = defineCancelApiObject(BookingAPI)
