import {CLOUDINARY_API_URL, CLOUDINARY_CONFIG_CLOUD_NAME} from "../config";
import {getImageUploadPresetForCloudinary} from "./helpers";

export const handleImageChange = (info) => {
    if (info.file.status === 'uploading') {
        return true;
    } else if (info.file.status === 'done') {
        return false;

    // Get this url from response in real world.
    // getBase64(info.file.originFileObj, profilePhotoUrl => {
    //         setImageLoading(false);
    //         setProfilePhotoUrl(profilePhotoUrl);
    //         console.log(`profilePhotoUrl`, profilePhotoUrl)
    //     }
    // );
    }
};

// very important code that makes sure that file uploading status turns to 'done'
export const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

export const validateAndUpload = async (file, typeOfServiceProvider) => {

    let secure_url, error;

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        error = 'You can only upload JPG/PNG file!';
        return { 'secure_url' : null, 'error': error };
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        error = 'Image must smaller than 2MB!';
        return { 'secure_url' : null, 'error': error };
    }

    // pass the image file and call uploadImage.
    // Handle error from uploadImage and pass it to FC to be displayed in UI.
    try {
        secure_url = await uploadImage(file, typeOfServiceProvider);
    } catch (e) {
        error = 'Error while uploading image to cloudinary. Please contact technical support.'
        return { 'secure_url' : null, 'error': error };
    }

    return { 'secure_url' : secure_url, 'error': error };
}

export const uploadImage = async (file, typeOfServiceProvider) => {
    const data = new FormData()
    data.append("file", file)
    data.append("upload_preset", getImageUploadPresetForCloudinary(typeOfServiceProvider))
    data.append("cloud_name", CLOUDINARY_CONFIG_CLOUD_NAME)

    let response
    try {
        response = await fetch(CLOUDINARY_API_URL, {
            method: "post",
            body: data
        });
    } catch (e) {
        throw new Error('Error while uploading image to cloudinary.')
    }

    console.log(`Done uploading image to cloudinary. `, response);
    return (await response.json()).secure_url

    // Asynchronously calling the API - this didn't work to catch the error and display in UI
    // fetch(CLOUDINARY_API_URL, {
    //     method: "post",
    //     body: data
    // })
    //     .then(resp => resp.json())
    //     .then(data => {
    //         console.log(`Done uploading image to cloudinary. `, data)
    //         return data.secure_url;
    //     })
    //     .catch(error => console.log(`Error while uploading image to cloudinary: `, error))
}

