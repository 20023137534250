import React, { useState, useContext, useEffect } from "react";
import { Modal, Button } from "antd";
import { LanguageContext } from "../Language/LanguageContext";
import { languageContent } from "../Language/languageContent";

const TermsAndCondtionModal = () => {
  const { language } = useContext(LanguageContext);

  //Languages
  const {
    modalOneTitle,
    modalOneContent,
    modalTwoTitle,
    modalTwoContent,
    yes,
    no,
  } = languageContent[language];

  const [openModal, setOpenModal] = useState(false);
  const [secondModalVisible, setSecondModalVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenModal(true);
    }, 7000); // 10 seconds

    return () => clearTimeout(timeout);
  }, []);

  const handleFirstModalOk = () => {
    setOpenModal(false);
  };

  const handleFirstModalCancel = () => {
    setOpenModal(false);
    setSecondModalVisible(true); // Open the second modal
  };

  const handleSecondModalOk = () => {
    setSecondModalVisible(false);
    setTermsVisible(true); // Open the terms and conditions modal
  };

  const handleSecondModalCancel = () => {
    setSecondModalVisible(false);
  };
  const handleTermsOk = () => {
    setTermsVisible(false); // Close the terms and conditions modal
  };

  return (
    <>
      <Modal
        title={
          <div
            style={{
              textAlign: "center",
              color: "#CE257A",
              fontSize: "24px",
              fontFamily: "Sora, sans-serif",
            }}
          >
            {modalOneTitle}
          </div>
        }
        centered
        open={openModal}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        footer={
          <div style={{ textAlign: "center" }}>
            <Button
              style={{
                width: "100px",
                backgroundColor: "#CE257A",
                borderColor: "#CE257A",
                color: "white",
                marginRight: "10px",
                fontFamily: "Sora, sans-serif",
              }}
              onClick={handleFirstModalOk}
            >
              {yes}
            </Button>
            <Button
              style={{
                width: "100px",
                backgroundColor: "#CE257A",
                borderColor: "#CE257A",
                color: "white",
                fontFamily: "Sora, sans-serif",
              }}
              onClick={handleFirstModalCancel}
            >
              {no}
            </Button>
          </div>
        }
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "14px",
            fontFamily: "Sora, sans-serif",
          }}
        >
          {modalOneContent}
        </p>
      </Modal>
      <Modal
        title={<div style={{ textAlign: "center" }}>{modalTwoTitle}</div>}
        centered
        visible={secondModalVisible}
        onOk={handleSecondModalOk}
        onCancel={handleSecondModalCancel}
        footer={
          <div
            style={{
              textAlign: "center",
              color: "#CE257A",
              fontSize: "24px",
              fontFamily: "Sora, sans-serif",
            }}
          >
            <Button
              style={{
                width: "100px",
                backgroundColor: "#CE257A",
                borderColor: "#CE257A",
                color: "white",
                marginRight: "10px",
                fontFamily: "Sora, sans-serif",
              }}
              onClick={handleSecondModalOk}
            >
              {yes}
            </Button>
            <Button
              style={{
                width: "100px",
                backgroundColor: "#CE257A",
                borderColor: "#CE257A",
                color: "white",
                fontFamily: "Sora, sans-serif",
              }}
              onClick={handleSecondModalCancel}
            >
              {no}
            </Button>
          </div>
        }
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "14px",
            fontFamily: "Sora, sans-serif",
          }}
        >
          {modalTwoContent}
        </p>
      </Modal>
      <Modal
        title={
          <div
            style={{ color: "#CE257A", fontSize: "20px", textAlign: "center" }}
          >
            Terms and Conditions
          </div>
        }
        centered
        open={termsVisible}
        onOk={handleTermsOk}
        onCancel={handleTermsOk}
        style={{
          overflow: "auto",
          fontSize: "14px",
          fontFamily: "Sora, sans-serif",
        }}
        bodyStyle={{ maxHeight: "45vh", overflowY: "auto" }}
        width={"50%"}
        okButtonProps={{
          style: {
            backgroundColor: "#CE257A",
            borderColor: "#CE257A",
            color: "white",
          },
        }}
        cancelButtonProps={{
          style: {
            backgroundColor: "#CE257A",
            borderColor: "#CE257A",
            color: "white",
          },
        }}
      >
        <p>
          <b style={{ color: "#CE257A" }}>
            {" "}
            These terms and conditions (the “Terms”) are a legal contract
            according to the Electronic Signature Proclamation No. 1072/2018
            between Mogzit In-Home Care PLC (“Mogzit”) and you{" "}
          </b>
          , whether personally or on behalf of an entity “you”. The Terms
          explain how you are permitted to use the Services provided by and the
          website located at the URL www.mogzit.com (and all associated sites
          linked to www.mogzit.com by Mogzit, its subsidiaries and affiliated
          companies) (collectively, the “Site”). Unless otherwise specified, all
          references to “Site” include any software that Mogzit provides to you
          that allows you to access the Site from a mobile device (a “Mobile
          Application”).
          <br />
          <br />
          <b className="text-rose-600"> 1. Services</b>
          <br />
          <br /> Mogzit’s services are provided through the Site (which may
          include Mobile Applications), which is an in-home care online solution
          that offers users in-home child care services for parents in Addis
          Ababa (“Parents”) with convenience and affordable cost. Mogzit aims to
          be a link between freelance/full-time Care givers (“Care givers”) and
          Parents who have young children who need to be looked after at home on
          a regular or irregular basis. The service allows customers to book
          time slots when they need the service.
          <br />
          <br />
          <b className="text-rose-600">2.Privacy</b> <br />
          <br /> Mogzit is presently in compliance, with its privacy and
          security policies and with all obligations under applicable laws and
          regulations of Ethiopia in relation to the collection, use, transfer,
          storage, protection, disposal or disclosure of personally identifiable
          information or any other legally protected information collected from
          you. <br />
          <br />
          <b className="text-rose-600">3.Age Requirement</b>
          <br />
          <br /> By using this Site, you represent, acknowledge and agree that
          you are at least 18 years of age, or if you are under 18, you are
          accessing the Service through a parent or legal guardian. If you are a
          parent or legal guardian of a minor, you hereby agree to bind the
          minor to these Terms and to fully indemnify and hold harmless Mogzit
          if the Minor breaches any of these Terms. If you are not at least
          18 years old, you may not use the Site at any time or in any manner or
          submit any information to the Mogzit or the Site.
          <br />
          <br />{" "}
          <b className="text-rose-600">
            4.Parents’ and Care givers'Responsibilities
          </b>{" "}
          <br />
          <br />
          If you are a Parent, you accept responsibility for yourself and your
          child in the use of the Services. You acknowledge that your
          relationship for Care giving services is with your Care giver, and
          your obtaining services from the Care giver is solely at your own risk
          and you assume full responsibility for all risk associated therewith,
          to the extent permitted by law. By using the service, you agree to not
          hold Mogzit liable in any way for any malpractice or substandard
          treatment the care giver may render. Although the detailed profiles of
          the Care givers, customers reviews will help the Mogzit regulate the
          Care givers and ensure that the customers get the service which
          satisfies them, it is your responsibility to separately confirm that a
          Care giver is in good standing. The Care giver will accept
          responsibility for the Parents and themselves in using this Service.
          The Care giver is also responsible for the quality of the services you
          get during your use of the Service and for complying with all
          applicable laws in connection with your use of the service.
          <br />
          <br />
          <b className="text-rose-600"> 5.Payments and Subscriptions </b>
          <br />
          <br />
          <b className="text-rose-600"> (a) Subscriptions </b>
          <br />
          <br />
          <br />
          By registering for an account with Mogzit, you become a “Registered
          User” with access to certain password-restricted areas of the Site and
          to use certain services and materials offered on and through the Site
          (a “Subscription”). Each Subscription and the rights and privileges
          provided to a Registered User is personal and non-transferable. There
          is no fee that Mogzit charges for your Subscription, however,  Mogzit
          reserves the right to start charging prices for Subscriptions at any
          time. Notwithstanding the foregoing, Mogzit shall notify Registered
          Users of any decision to charge price or price changes thirty (30)
          days prior to any change. Mogzit shall honor any Subscription prices
          until the expiration of the applicable Subscription Term. Each
          subscription’s automatic renewal is for the same period of time as
          your original subscription. You may cancel your subscription at any
          time by contacting Mogzit at EMAILmogzitinhomecare.info@gmail.com
          <br />
          <br /> <b className="text-rose-600">(b) Payment </b>
          <br />
          <br />
          Payments for Mogzit in-home child care services can be facilitated
          either through cash transactions or online transfers. A variety of
          payment schedules are offered, including hourly, daily, weekly, and
          monthly options. The cost of services is subject to change depending
          on the length and regularity of the bookings. It is required that
          payments be made in advance or at the time the service is provided, as
          determined during the booking process. In the event of any extra fees
          or charges, these will be disclosed to the customer prior to the
          delivery of the service.
          <br />
          <br /> <b className="text-rose-600">
            (c) Cancellations and Refunds
          </b>{" "}
          <br />
          <br />
          Customers are advised to make cancellations in advance, as outlined
          during the booking process. Our cancellation policy may apply to
          refunds for cancellations or no-shows. In instances where the care
          giver initiates the cancellation, refunds will be evaluated on a
          case-by-case basis.
          <br />
          <br /> <b className="text-rose-600">
            6. Links to Third-Party Sites
          </b>{" "}
          <br />
          <br />
          Mogzit’s Site may link to third-party websites, advertisers, or
          services that are not owned or controlled by Mogzit. Where
          Mogzit provides such third-party links, no representations or
          endorsements are made in connection with such sites. Mogzit has no
          control over, and assumes no responsibility for, the content, privacy
          policies, or practices of any third-party websites or services. If you
          access a third-party website from Mogzit Sites, you do so at your own
          risk, and you understand that this Term and Mogzit’s Privacy Policy do
          not apply to your use of such sites. You expressly relieve Mogzit from
          any and all liability arising from your use of any third-party website
          or services or third-party-owned content. Additionally, your dealings
          with or participation in promotions of advertisers found on
          Mogzit Sites, including payment and delivery of goods, and any other
          terms (such as warranties) are solely between you and such
          advertisers. You agree that Mogzit shall not be responsible for any
          loss or damage of any sort relating to your dealings with such
          advertisers. We encourage you to be aware of when you leave the
          Mogzit Sites, and to read the terms and conditions and privacy policy
          of any third-party website or service that you visit.
          <br />
          <br />{" "}
          <b className="text-rose-600">7.Governing Law and Arbitration</b>{" "}
          <br />
          <br />
          Unless the Parties mutually agree otherwise in writing, this Agreement
          shall be governed by and construed in accordance with the laws of
          Ethiopia, regardless of the laws that might otherwise govern under
          applicable principles of conflicts of law. The Parties understand and
          agree that any Party may give to the other at any time notice in
          writing that a dispute has arisen relating to this Agreement or the
          Parties’ interactions. If the dispute is not resolved by agreement in
          writing between the Parties within fourteen (14) days of the notice
          being given, the dispute shall be resolved in accordance with the rest
          of this Section. Any dispute arising out of or in connection with this
          Agreement, including any question regarding its existence, validity or
          termination, not resolved within fourteen (14) days in accordance with
          the preceding Section shall be referred to and finally resolved by the
          Addis Ababa Chamber of Commerce and Sectorial Association Arbitration
          Institute (AACCSA AI) in accordance with the AACCSA AI Arbitration
          Rules, as amended from time to time and by the rest of this clause.
          The seat of arbitration shall be Addis Ababa, Ethiopia and there shall
          be a single arbitrator appointed from and by AACCSA AI. Although the
          Parties mutually agree and intend that any disputes arising under this
          Agreement shall be resolved only by binding arbitration in accordance
          with the proceeding Sections, they further agree that, should this
          arbitration clause be struck down or otherwise disregarded, the venue
          for any action to enforce the terms and conditions of this agreement
          shall be determined by Ethiopian law and courts.
          <br />
          <br /> <b className="text-rose-600">8.Contact Us</b>
          <br />
          <br /> If you have any questions about these Terms or otherwise need
          to contact Mogzit for any reason, you can reach Mogzit
          at Mogzit In-Home Care PLC with a copy to
          mogzitinhomecare.info@gmail.com or call Mogzit at +251 902 993278 or
          +251952133990
          <br />
          <br /> To read full part of term and conditions
          <a
            href="https://mogzit.com/terms-and-conditions-detail/"
            className="ml-1 text-rose-600"
          >
            click here
          </a>
        </p>
      </Modal>
    </>
  );
};

export default TermsAndCondtionModal;
