import { Button, Space, Tag } from "antd";
import { Link } from "react-router-dom";
import React from "react";
import { toFirstCharUpperCase } from "../../../utils/helpers";

export const columns = (
  handleCompleteClick,
  handleDeleteClick,
  bookingStatus
) => [
  {
    title: <span className="text-[#CE257A] font-sora">ID</span>,
    dataIndex: "id",
    key: "id",
    // render: text => <a>{text}</a>,
  },
  {
    title: <span className="text-[#CE257A] font-sora">Booked</span>,
    dataIndex: "booked",
    key: "booked",
    render: (text, record) => (
      <Space size="middle">
        <Link to={`/${record.bookingType}/${record.bookedItemId}`}>
          {toFirstCharUpperCase(record.bookingType)} #{record.bookedItemId}
        </Link>
      </Space>
    ),
  },
  {
    title: <span className="text-[#CE257A] font-sora">First Name</span>,
    dataIndex: "firstName",
    key: "firstName",
    render: (text) => <span className="font-sora">{text}</span>,
  },
  {
    title: <span className="text-[#CE257A] font-sora">Middle Name</span>,
    dataIndex: "middleName",
    key: "middleName",
    render: (text) => <span className="font-sora">{text}</span>,
  },
  {
    title: <span className="text-[#CE257A] font-sora">Last Name</span>,
    dataIndex: "lastName",
    key: "lastName",
    render: (text) => <span className="font-sora">{text}</span>,
  },
  {
    title: <span className="text-[#CE257A] font-sora">Email</span>,
    dataIndex: "email",
    key: "email",
    render: (text) => <span className="font-sora">{text}</span>,
  },
  {
    title: <span className="text-[#CE257A] font-sora">Phone Number</span>,
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    render: (text) => <span className="font-sora">{text}</span>,
  },
  {
    title: <span className="text-[#CE257A] font-sora">Alt Phone Number</span>,
    dataIndex: "altPhoneNumber",
    key: "altPhoneNumber",
    render: (text) => <span className="font-sora">{text}</span>,
  },
  {
    title: <span className="text-[#CE257A] font-sora">No. Of Children</span>,
    dataIndex: "numberOfChildren",
    key: "numberOfChildren",
    render: (numberOfChildren) => {
      let color = numberOfChildren > 5 ? "geekblue" : "#CE257A";
      if (numberOfChildren === 0) color = "red";
      return (
        <Tag color={color} key={numberOfChildren}>
          {numberOfChildren}
        </Tag>
      );
    },
  },
  {
    title: <span className="text-[#CE257A] font-sora">Place Of Residince</span>,
    dataIndex: "placeOfResidence",
    key: "placeOfResidence",
    render: (text) => <span className="font-sora">{text}</span>,
  },
  {
    title: <span className="text-[#CE257A] font-sora">Contact Preference</span>,
    dataIndex: "contactPreference",
    key: "contactPreference",
    render: (text) => <span className="font-sora">{text}</span>,
  },
  {
    title: <span className="text-[#CE257A] font-sora">Booked Date</span>,
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text) => <span className="font-sora">{text}</span>,
  },
  // {
  //     title: 'Deleted?',
  //     dataIndex: 'isDeleted',
  //     key: 'isDeleted',
  //     render: (isDeleted) => <Switch checked={isDeleted} disabled/>
  // },
  ...(bookingStatus === "NEW"
    ? [
        {
          title: <span className="text-[#CE257A] font-sora">Operations</span>,
          key: "actions",
          render: (text, record) => (
            <Space size="middle">
              {!record.isDeleted && !record.isCompleted && (
                <Button
                  type="primary"
                  onClick={() => handleCompleteClick(record.id)}
                  className="bg-[#CE257A] text-white hover:bg-brown!important"
                >
                  Complete
                </Button>
              )}
              {!record.isDeleted && !record.isCompleted && (
                <Button
                  type="primary"
                  danger
                  onClick={() => handleDeleteClick(record.id)}
                >
                  Delete
                </Button>
              )}
            </Space>
          ),
        },
      ]
    : []),
];
