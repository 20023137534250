import React, { useState } from "react";
import loginImg from "../../assets/loginPageLogo.png";
import "./LoginPage.css";
import { Modal } from "antd";
function LoginPage({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(""); // eslint-disable-line no-unused-vars

  const handleSubmit = (event) => {
    event.preventDefault();
    if (username === "admin" && password === "password") {
      setRole("admin");
      Modal.success({
        content: "Welcome admin",
      });
      onLogin("admin");
    } else if (username === "editor" && password === "password") {
      setRole("editor");
      Modal.success({
        content: "Welcome editor",
      });
      onLogin("editor");
    } else {
      Modal.error({
        content: "Invalid username or password",
      });
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full">
      <div className="hidden sm:block">
        <img className="w-full h-full object-cover" src={loginImg} alt="" />
      </div>

      <div className="bg-gray-100 flex flex-col justify-center">
        <form
          className="max-w-[400px] w-full mx-auto bg-white p-4"
          onSubmit={handleSubmit}
        >
          <h2 className="text-4xl font-bold text-center py-6 sora text-[#CE257A]">
            Mogzit-In Home Care
          </h2>

          <div className="flex flex-col py-2 sora text-[#CE257A]">
            <label>Username</label>
            <input
              className="border p-2"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
            />
          </div>
          <div className="flex flex-col py-2 sora text-[#CE257A]">
            <label>Password</label>
            <input
              className="border p-2"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </div>
          <button
            type="submit"
            className="border w-full my-5 py-2 bg-[#CE257A] hover:bg-[#CE257A] text-white sora "
          >
            Sign In
          </button>
          <div className="flex justify-between">
            <p className="flex items-center sora text-[#CE257A]">
              <input className="mr-2" type="checkbox" /> Remember Me
            </p>
            {/* <p>Create an account</p> */}
          </div>
        </form>
      </div>
    </div>
  );
}
export default LoginPage;
