// import { homeTutorsData } from "../../data/hometutorData";

export const languageContent = {
  en: {
    home: "Home",
    about: "About",
    services: "Services",
    contact: "Contact",
    register: "Register",
    servicesText: "Our services covers a wide range,from infants to teenagers",
    header: "We will find you the best care giver",
    slogan: "We will find you the best nanny",
    overView: "Overview",
    info: "Information",
    headOffice: "Head Office",
    cookiePolicy: "Cookie Policy",
    terms: "Terms and Conditions",
    privacyPolicy: "Privacy Policy",
    building: "2nd Floor, YeshiTam Building ,",
    address: "Kirkos Sub-City , Addis",
    city: "Addis Ababa,Ethiopia. +251 902",
    service: "Care Giver Services",
    serviceInfo: "Check out care giving servies we give",
    serviceNameOne: "Cook Cleaner Service",
    serviceNameTwo: "Home Tutor Service",
    serviceNameThree: "Nanny Service",
    buttonTitle: "See the Service",
    help: "Need Help",
    helpMessage:
      "If you have any questions, please feel free to contact our customers service or drop us a line via : info@mogzit.com , We are more than happy to help you.",
    homeTutorsList: "Home Tutors List",
    nanniesList: "Nannies List",
    filterByService: "  Filter by Service",
    filterByWage: " Filter by Wage",
    seeDetail: "See Detail",
    certified: "  Certified plus Experience",
    notCertified: "Experience only",
    hour: "hour",
    month: "month",
    noTutor: "No Home Tutors avaliable",
    noNanny: "No nannies available",
    ID: "ID",
    homeTutorInfo: "Home Tutor Info",
    nannies: "Nanny Info",
    homeTutors: "Home Tutors ",
    nannyInfo: "Nanny Info",
    nameContent: "Name",
    placeContent: "Place",
    typeofServiceContent: "Type of Service",
    priceContent: "Price",
    ageContent: "Age",
    languageContent: "Language",
    yearOfExperienceContent: "Year of Experience",
    aboutMeContent: "About Me",
    majorContent: "Major",
    educationContent: "Education",
    levelContent: "Levels",
    subjectContent: "Subjects",
    hobbyContent: "Hobbies",
    backToListView: "Back to List View",
    book: "Book",
    brHour: "Br/hour",
    brMonth: "Br/month",
    alertContentOne: " We believe in transparent pricing.",
    alertContentTwo: "   For providing qualified and carefully examined",
    alertContentThree: "  we charge aservice fee.",
    alertContentFour: "   When you",
    alertContentFive: "  sign the contract",
    alertContentSix: "   with a",
    alertContentSeven: "  you will be charged",
    alertContentEight: "   service fee of one month",
    alertContentNine: "   salary.",
    bookingInformation: "You are going to book a ",
    bookingInformationTwo: "named",
    bookingInformationThree: "with price",
    homeTutor: "Home tutor",
    nanny: "Nanny",
    priceInfo: "Price Information",
    firstName: "First Name",
    firstNameMessage: "Please input your first name!",
    middleName: "Middle Name",
    middleNameMessage: "Please input your middle name",
    lastName: "Last Name",
    lastNameMessage: "Please input your last name",
    phoneNumber: "Phone Number",
    phoneNumberMessage: "Please input your phone number!",
    alternativePhoneNumber: "Alternative Phone number",
    alternativePhoneNumberMessage: "Please input your alternate phone number!",
    alternativePhonePlaceHolder:
      "If we cannot reach you with your main number, we will call this number.",
    email: "Email",
    emailMessage: "Write your correct email including @ and dot (.)",
    placeOfResidence: "Place of Residence",
    placeOfResidenceMessage: "Please input your place of residence!",
    placeOfResidencePlaceHolder:
      "This helps us to find a near-by service provider for you.",
    contactPreference: "Contact Preference",
    contactPreferenceMessage: "Please input your contact preference!",
    contactPreferencePlaceHolder: "When we shall call you?",
    clientMessageLabel: "Messsage",
    clientMessageInfo: "Please input your message!",
    clientMessageInfoPlaceHolder:
      "Do you want to tell us something about your need or about how you heard about Mogzit.com?",
    agreement: "Agreement",
    agreementPlaceHolder:
      "To proceed, you need to agree with our terms and conditions",
    agreementMessageOne: "Agree to our",
    agreementMessageTwo: "Terms and Conditions",
    bookNanny: "Book a Nanny",
    bookHomeTutor: "Book a Home Tutor",
    modalOneTitle: " Have you read",
    modalOneContent: "  Our Terms and Condtions!!!",
    modalTwoTitle: "Do you want to read now",
    modalTwoContent: "  Mogzit-In Home Care Terms and Condtions",
    yes: "Yes",
    no: "No",
    thank: "Thank you for using us",
    soon: "We will contact you soon",
    confirmation: " Confirmation",
    confirmationQuestion: "Are you sure you want to book?",
    alertCheckBox: "Please agree to the terms and conditions.",
    alertField: "Please fill all required fields.",
    bookedSuccess: "  You have booked sucessfully",
    ok: "ok",
    becomeACG: "Become A Care Giver",
    bookingConfirmation: `  Are you sure you want to book {serviceProvider.firstName} as a{" "}
    {text.serviceName}?`,
    bookingConfirmationQuestion:
      "   Did you give us all the information we need to contract you?",
    Confirmation: " Confirmation",
  },
  am: {
    home: "ዋና ግጽ",
    about: "ስለ-እኛ",
    services: "አገልግሎቶች",
    contact: "አድራሻ",
    register: "ይመዝገቡ",
    servicesText: "አገልግሎታችን ከጨቅላ እስከ ታዳጊ ወጣቶች ድረስ ያለውን ሰፊ ​​ክልል ይሸፍናል",
    header: "ምርጥ እንክብካቤ ሰጪ እናገኝሃለን።",
    slogan: "ምርጥ ሞግዚት እናገኝሃለን።",
    overView: "አጠቃላይ እይታ",
    info: "መረጃ",
    headOffice: "ዋና መስሪያ ቤት",
    cookiePolicy: "የኩኪ ፖሊሲ",
    terms: "አተገባበሩና ​​መመሪያው",
    privacyPolicy: "የግል ፖሊሲ",
    building: "የሺታም ህንፃ 2ኛ ፎቅ",
    address: "ቂርቆስ ክፍለ ከተማ",
    city: "አዲስ አበባ፣ኢትዮጵያ። +251 902",
    service: "የእንክብካቤ ሰጪ አገልግሎቶች",
    serviceInfo: "የምንሰጣቸውን እንክብካቤ አገልግሎት ይመልከቱ",
    serviceNameOne: "የኩክ ማጽጃ አገልግሎት",
    serviceNameTwo: "የቤት አስጠኚ አገልግሎት",
    serviceNameThree: "ሞግዚት አገልግሎት",
    buttonTitle: "አገልግሎቱን ይመልከቱ",
    help: "እርዳታ ያስፈልጋል?",
    helpMessage:
      "ማንኛውም አይነት ጥያቄ ካሎት እባክዎን የደንበኞቻችንን አገልግሎት ለማነጋገር ነፃነት ይሰማዎ ወይም በ mogzitinhomecare.info@gmail.com በኩል መስመር ይጣሉልን, እርስዎን ለመርዳት በጣም ደስተኞች ነን",
    homeTutorsList: "የቤት አስጠኚዎች ዝርዝር",
    nanniesList: "የሞግዚቶች ዝርዝር",
    filterByService: "በአገልግሎት አጣራ",
    filterByWage: "በደመወዝ አጣራ",
    seeDetail: "ዝርዝሩን ይመልከቱ",
    certified: " ✅ የተረጋገጠ",
    notCertified: "❌ አልተረጋገጠም",
    hour: "ሰአት",
    month: "ወር",
    noTutor: "ምንም የቤት አስተማሪ የለም",
    noNanny: "ምንም ሞግዚቶች አይገኙም",
    ID: "የመታወቂያ ቁጥር",
    homeTutorInfo: "የቤት አስተማሪ መረጃ",
    nannyInfo: "የሞግዚት መረጃ",
    nameContent: "ስም",
    placeContent: "ቦታ",
    typeofServiceContent: "የአገልግሎት ዓይነት",
    priceContent: "ዋጋ",
    ageContent: "ዕድሜ",
    languageContent: "ቋንቋ",
    yearOfExperienceContent: "የልምድ ዓመት",
    aboutMeContent: "ስለ እኔ",
    majorContent: "ዋና",
    educationContent: "ትምህርት",
    levelContent: "ደረጃዎች",
    subjectContent: "የት/ት ዓይነት",
    hobbyContent: "መዝናኛ",
    backToListView: "ወደ ዝርዝር እይታ ተመለስ",
    book: "መምረጥ",
    brHour: "ብር/ሰዓት",
    brMonth: "ብር/ወር",
    alertContentOne: "በግልጽ ዋጋ እናምናለን",
    alertContentTwo: "ብቁ እና በጥንቃቄ የተመረመሩ ለማቅረብ",
    alertContentThree: "የአገልግሎት ክፍያ እንከፍላለን",
    alertContentFour: "አንተ መቼ",
    alertContentFive: "ኮንትራቱን ይፈርሙ",
    alertContentSix: "ከ ጋር",
    alertContentSeven: "መክፈል አለብህ",
    alertContentEight: "የአንድ ወር የአገልግሎት ክፍያ",
    alertContentNine: "ደሞዝ",
    bookingInformation: "መያዝ ነው",
    bookingInformationTwo: "የተሰየመ",
    bookingInformationThree: "በዋጋ",
    homeTutor: "የቤት አስተማሪ",
    nanny: "ሞግዚት",
    priceInfo: "የዋጋ መረጃ",
    firstName: "የመጀመሪያ ስም",
    firstNameMessage: "እባክዎ የመጀመሪያ ስምዎን ያስገቡ!",
    middleName: "የአባት ስም",
    middleNameMessage: "እባክዎ የአባት ስምዎን ያስገቡ",
    lastName: "የአያት ስም",
    lastNameMessage: "እባክዎ የአያት ስምዎን ያስገቡ",
    phoneNumber: "ስልክ ቁጥር",
    phoneNumberMessage: "እባክዎ ስልክ ቁጥርዎን ያስገቡ!",
    alternativePhoneNumber: "አማራጭ ስልክ ቁጥር",
    alternativePhoneNumberMessage: "እባክዎ ተለዋጭ ስልክ ቁጥርዎን ያስገቡ!",
    alternativePhonePlaceHolder: "በዋናው ስልክህ ልናገኝህ ካልቻልን ወደዚህ ቁጥር እንጠራዋለን።",
    email: "ኢሜል",
    emailMessage: " @ እና ነጥብ (.)ን ጨምሮ ትክክለኛውን ኢሜልዎን ይፃፉ።",
    placeOfResidence: "የመኖሪያ ቦታ",
    placeOfResidenceMessage: "እባክዎ የመኖሪያ ቦታዎን ያስገቡ!",
    placeOfResidencePlaceHolder: "ይህ ለእርስዎ ቅርብ የሆነ አገልግሎት አቅራቢ እንድናገኝ ይረዳናል።",
    contactPreference: "የእውቂያ ምርጫ",
    contactPreferenceMessage: "እባክዎ የእውቂያ ምርጫዎን ያስገቡ!",
    contactPreferencePlaceHolder: "መቼ ነው የምንጠራህ?",
    clientMessageLabel: "መልእክት",
    clientMessageInfo: "እባክዎ መልእክትዎን ያስገቡ!",
    clientMessageInfoPlaceHolder:
      "ስለ ፍላጎትህ ወይም ስለ Mogzit.com እንዴት እንደሰማህ አንድ ነገር ልትነግረን ትፈልጋለህ?",
    agreement: "ስምምነት",
    agreementPlaceHolder: "ለመቀጠል በእኛ ውሎች እና ሁኔታዎች መስማማት አለብዎት",
    agreementMessageOne: "በእኛ ተስማማ",
    agreementMessageTwo: "አተገባበሩና ​​መመሪያው",
    bookNanny: "ሞግዚት ያስመዝግቡ",
    bookHomeTutor: "የቤት አስተማሪ እና እንክብካቤን ያስይዙ",
    modalOneTitle: " የእኛ ውሎች እና ሁኔታዎች",
    modalOneContent: "  አንብበው ያውቃሉ?",
    modalTwoTitle: "አሁን ማንበብ ትፈልጋለህ",
    modalTwoContent: "የ Mogzit-In Home Care ውሎች እና ሁኔታዎች?",
    yes: "አዎ",
    no: "አይ",
    thank: "አገልግሎታችንን ስለተጠቀሙ እናመሰግናለን",
    soon: "በቅርቡ እናነጋግርዎታለን",
    confirmation: "ማረጋገጫ",
    confirmationQuestion: "እርግጠኛ ነህ ቦታ ማስያዝ ትፈልጋለህ?",
    alertCheckBox: "እባክዎ በውሎቹ እና ሁኔታዎች ይስማሙ።",
    alertField: "እባክዎ ሁሉንም አስፈላጊ መስኮች ይሙሉ።",
    bookedSuccess: "በተሳካ ሁኔታ ቦታ አስይዘሃል",
    ok: "እሺ",
    becomeACG: "እንደ እንክብካቤ ሰጪ ለመመዝገብ",
    bookingConfirmation: ` እርግጠኛ ነዎት {serviceProvider.firstName}ን እንደ {text.serviceName} ቦታ ማስያዝ ይፈልጋሉ።
   ?`,
    bookingConfirmationQuestion: "አንተን ለመዋዋል የሚያስፈልገንን መረጃ ሁሉ ሰጥተኸናል?",
    Confirmation: " ማረጋገጫ",
  },
};
