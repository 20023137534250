import React, { useState, useEffect } from "react";
import { Layout, Menu, Drawer, Button, Input, Dropdown, Modal } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  TeamOutlined,
  OrderedListOutlined,
  EditFilled,
  ControlFilled,
  PlusSquareFilled,
  CheckCircleFilled,
  DeleteFilled,
  RocketOutlined,
  BookOutlined,
  DashboardOutlined,
  BarChartOutlined,
  SettingOutlined,
  ProfileOutlined,
  ExperimentOutlined,
  NotificationOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import MogzitLogo from "../../../assets/Footerlogo.png";
import "./AdminDashboard.css";
import { Link, Route, Routes, /*useMatch*/ } from "react-router-dom";
import AdminListServiceProvider from "../AdminListServiceProvider/AdminListServiceProvider";
import AdminAddServiceProvider from "../AdminAddServiceProvider/AdminAddServiceProvider";
import ManageNewBookings from "../AdminManageBookings/ManageNewBookings";
import ManageDeletedBookings from "../AdminManageBookings/ManageDeletedBookings";
import ManageCompletedBookings from "../AdminManageBookings/ManageCompletedBookings";
import ReportDashboard from "../ReportDashboard/ReportDashboard";
import AdminProfile from "../AdminProfile/AdminProfile";
import AdminSettings from "../AdminSettings/AdminSettings";
import AdminFooter from "../../../components/AdminFooter/AdminFooter";
import LoginPage from "../../LoginPage/LoginPage";
import NotificationRing from "../../../assets/notification.png";
// import GlobalSearch from "../AdminGlobalSearch/GlobalSearch";
import GlobalSearchResults from "../AdminGlobalSearch/GlobalSearchResults";
import UserService from "../../../services/UserService";
import RolesRoute from "../../../components/security/RolesRoute";

import RenderOnAnonymous from "../../../components/security/RenderOnAnonymous";
import RenderOnAuthenticated from "../../../components/security/RenderOnAuthenticated";
import Welcome from "../../admin/Welcome";


const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const AdminDashboard = () => {
  // The `path` lets us build <Route> paths that are relative to the parent route,
  // while the `url` lets us build relative links.
  // let { path, url } = useMatch();

  const [collapsed, setCollapsed] = useState(window.innerWidth > 768);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  //routing problem
  // useEffect(() => {
  //   console.log("MyComponent has been mounted");
  // }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //for knowing the role
  const [userRole, setUserRole] = useState("");

  const handleLogin = (role) => {
    setUserRole(role);
  };
  //Logout menu
  const userMenu = (
    <Menu>
      <Menu.Item key="0" className="myCustomMenuItem">
        <div
          onClick={() => {
            Modal.confirm({
              className: "myCustomModal",
              title: "Are you sure you want to log out?",
              icon: <ExclamationCircleOutlined />,
              content: "You will be redirected to the home page.",
              okText: "Yes",
              cancelText: "No",
              onOk() {
                window.location.href = "https://www.mogzit.com";
              },
            });
          }}
        >
          Sign Out
        </div>
      </Menu.Item>
      <Menu.Item key="1" className="myCustomMenuItem">
        <Link to={`/adminSettings/settings`}>Change Password</Link>
      </Menu.Item>
    </Menu>
  );
  //notification menu
  const notifications = [
    // notification data
  ];
  const [visible, setVisible] = useState(false);

  const notificationMenu = (
    <div
      className="myCustomDropdown"
      style={{ width: "350px", backgroundColor: "white" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
          backgroundColor: "white",
          color: "#CE257A",
        }}
      >
        <h3 className="myCustomTitle">Notifications</h3>
        <Button icon={<CloseOutlined />} onClick={() => setVisible(false)} />
      </div>
      <div style={{ textAlign: "right", padding: "10px" }}>
        <Button type="link" className="myCustomLink">
          Mark all as read
        </Button>
      </div>

      {notifications.length > 0 ? (
        notifications.map((notification, index) => (
          <div key={index}>{/* Render notification */}</div>
        ))
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={NotificationRing} alt="No notifications" />
          <h3 className="myCustomNoData" style={{ color: "#CE257A" }}>
            No Data
          </h3>
          <p
            className="myCustomMessage"
            style={{ color: "grey", marginBottom: "40px" }}
          >
            You have no new notifications
          </p>
        </div>
      )}
    </div>
  );
  //for global search
  // eslint-disable-next-line no-unused-vars
  const [searchResults, setSearchResults] = useState([]);
  return (
    <>
      <Layout style={{ background: "#black" }}>
        {isMobile ? (
          <Drawer
            open={!collapsed}
            onClose={() => setCollapsed(true)}
            placement="left"
            style={{ background: "#CE257A" }}
          >
            <Menu
              // theme="dark"
              //    style={{ background: '#CE257A' }}
              style={{ background: "#CE257A", color: "white" }}
              mode="inline"
              defaultSelectedKeys={["list-bookings"]}
              defaultOpenKeys={["manage-bookings"]}
            >
              <SubMenu
                key="report-dashboard"
                icon={<DashboardOutlined />}
                title="Dashboard"
              >
                {/* TODO Use ContextAPI or Redux to manage the state and then divide the bookings */}
                <Menu.Item key="report" icon={<BarChartOutlined />}>
                  <Link to={`/admin/dashboard/main-dashboard`}>
                    Main Dashboard
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="manage-bookings"
                icon={<ControlFilled />}
                title="Manage Bookings"
              >
                {/* TODO Use ContextAPI or Redux to manage the state and then divide the bookings */}
                <Menu.Item key="new-bookings" icon={<PlusSquareFilled />}>
                  <Link to={`/admin/bookings/new`}>New Bookings</Link>
                </Menu.Item>
                <Menu.Item
                  key="completed-bookings"
                  icon={<CheckCircleFilled />}
                >
                  <Link to={`/admin/bookings/completed`}>
                    Completed Bookings
                  </Link>
                </Menu.Item>
                <Menu.Item key="deleted-bookings" icon={<DeleteFilled />}>
                  <Link to={`/admin/bookings/deleted`}>Deleted Bookings</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="manage-nannies"
                icon={<TeamOutlined />}
                title="Manage Nannies"
              >
                <Menu.Item key="add-nanny" icon={<EditFilled />}>
                  <Link to={`/admin/nanny/add`}>Add Nanny</Link>
                </Menu.Item>
                <Menu.Item key="list-nannies" icon={<OrderedListOutlined />}>
                  <Link to={`/admin/nanny`}>List Nannies</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="manage-cookCleaner"
                icon={<RocketOutlined />}
                title="Manage Cooks/Cleaners"
              >
                <Menu.Item key="add-cookCleaner" icon={<EditFilled />}>
                  <Link to={`/admin/cookCleaner/add`}>Add Cook/Cleaner</Link>
                </Menu.Item>
                <Menu.Item
                  key="list-cookCleaners"
                  icon={<OrderedListOutlined />}
                >
                  <Link to={`/admin/cookCleaner`}>List Cooks/Cleaners</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="manage-homeTutor"
                icon={<BookOutlined />}
                title="Manage Home Tutors"
              >
                <Menu.Item key="add-homeTutor" icon={<EditFilled />}>
                  <Link to={`/admin/homeTutor/add`}>Add Home Tutor</Link>
                </Menu.Item>
                <Menu.Item key="list-homeTutors" icon={<OrderedListOutlined />}>
                  <Link to={`/admin/homeTutor`}>List Home Tutors</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="admin-profile"
                icon={<UserOutlined />}
                title="Admin Profile"
              >
                <Menu.Item key="profile" icon={<UserOutlined />}>
                  <Link to={`/admin/adminProfile/profile`}>Profile</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="admin-settings"
                icon={<BookOutlined />}
                title="Admin Settings"
              >
                <Menu.Item key="settings" icon={<EditFilled />}>
                  <Link to={`/admin/adminSettings/settings`}>Settings</Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Drawer>
        ) : (
          <Sider
            style={{ background: "#CE257A" }}
            //   collapsible
            collapsed={collapsed}
            onCollapse={() => setCollapsed(!collapsed)}
            width={250}
          >
            {collapsed ? (
              <a href={"/"}>
                <img
                  className="image-collapsed"
                  src={MogzitLogo}
                  alt="mogzit logo"
                />
              </a>
            ) : (
              <a href={"/"}>
                <img
                  className="image-uncollapsed"
                  src={MogzitLogo}
                  alt="mogzit logo"
                />
              </a>
            )}
            <Menu
              style={{ background: "#CE257A", color: "white" }}
              mode="inline"
              defaultSelectedKeys={["list-bookings"]}
              defaultOpenKeys={["report-dashboard"]}
            >
              <SubMenu
                key="report-dashboard"
                icon={<DashboardOutlined />}
                title="Dashboard"
              >
                {/* TODO Use ContextAPI or Redux to manage the state and then divide the bookings */}
                <Menu.Item key="report" icon={<BarChartOutlined />}>
                  <Link to={`/admin/dashboard/main-dashboard`}>
                    Main Dashboard
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="manage-bookings"
                icon={<ControlFilled />}
                title="Manage Bookings"
              >
                {/* TODO Use ContextAPI or Redux to manage the state and then divide the bookings */}
                <Menu.Item key="new-bookings" icon={<PlusSquareFilled />}>
                  <Link to={`/admin/bookings/new`}>New Bookings</Link>
                </Menu.Item>
                <Menu.Item
                  key="completed-bookings"
                  icon={<CheckCircleFilled />}
                >
                  <Link to={`/admin/bookings/completed`}>
                    Completed Bookings
                  </Link>
                </Menu.Item>
                <Menu.Item key="deleted-bookings" icon={<DeleteFilled />}>
                  <Link to={`/admin/bookings/deleted`}>Deleted Bookings</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="manage-nannies"
                icon={<TeamOutlined />}
                title="Manage Nannies"
              >
                <Menu.Item key="add-nanny" icon={<EditFilled />}>
                  <Link to={`/admin/nanny/add`}>Add Nanny</Link>
                </Menu.Item>
                <Menu.Item key="list-nannies" icon={<OrderedListOutlined />}>
                  <Link to={`/admin/nanny`}>List Nannies</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="manage-cookCleaner"
                icon={<RocketOutlined />}
                title="Manage Cooks/Cleaners"
              >
                <Menu.Item key="add-cookCleaner" icon={<EditFilled />}>
                  <Link to={`/admin/cookCleaner/add`}>Add Cook/Cleaner</Link>
                </Menu.Item>
                <Menu.Item
                  key="list-cookCleaners"
                  icon={<OrderedListOutlined />}
                >
                  <Link to={`/admin/cookCleaner`}>List Cooks/Cleaners</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="manage-homeTutor"
                icon={<ExperimentOutlined />}
                title="Manage Home Tutors"
              >
                <Menu.Item key="add-homeTutor" icon={<EditFilled />}>
                  <Link to={`/admin/homeTutor/add`}>Add Home Tutor</Link>
                </Menu.Item>
                <Menu.Item key="list-homeTutors" icon={<OrderedListOutlined />}>
                  <Link to={`/admin/homeTutor`}>List Home Tutors</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="admin-profile"
                icon={<ProfileOutlined />}
                title="Admin Profile"
              >
                <Menu.Item key="profile" icon={<UserOutlined />}>
                  <Link to={`/admin/adminProfile/profile`}>Profile</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="admin-settings"
                icon={<SettingOutlined />}
                title="Admin Settings"
              >
                <Menu.Item key="settings" icon={<EditFilled />}>
                  <Link to={`/admin/adminSettings/settings`}>Settings</Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
        )}
        <Layout
          className="site-layout"
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            background: "#ffffff",
          }}
        >
          <Header
            className="site-layout-background"
            style={{
              padding: 5,
              background: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{ color: "#CE257A", marginTop: "15px" }}
            />

            <p id="signedInUser" className="nav-link" style={{ marginRight: 15 }}>
              Signed in as {UserService.getUsername()} [with Role(s) {UserService.getRoles()}]
              <button onClick={() => UserService.doLogout()}>
                Logout
              </button>
            </p>

            <div>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search for a caregiver"
                style={{ width: "200px" }}
              />
              {/* <GlobalSearch setSearchResults={setSearchResults} path={path} /> */}
              <Dropdown
                menu={notificationMenu}
                trigger={["click"]}
                open={visible}
                onOpenChange={setVisible}
              >
                <Button
                  icon={<NotificationOutlined />}
                  style={{ color: "#CE257A", marginRight: "10px" }}
                />
              </Dropdown>
              <Dropdown menu={userMenu} trigger={["click"]}>
                <Button
                  icon={<UserOutlined />}
                  style={{ color: "#CE257A", marginRight: "10px" }}
                />
              </Dropdown>
            </div>
          </Header>
          <Content
            style={{
              flex: 1,
              width: "100%",
              color: "white",
              background: "#ffffff",
            }}
            className="content"
          >
            {/* <Switch>
              <Route
                exact
                path={[path, `${path}/dashboard/main-dashboard`]} //both paths lead to one component (path = /admin)
                render={(props) => <ReportDashboard {...props} />}
              />
              <Route
                exact
                path={[`${path}/bookings/new`]}
                render={(props) => <ManageNewBookings {...props} />}
              />
              <Route
                exact
                path={`${path}/bookings/completed`}
                render={(props) => <ManageCompletedBookings {...props} />}
              />
              <Route
                exact
                path={`${path}/bookings/deleted`}
                render={(props) => <ManageDeletedBookings {...props} />}
              />

              <Route
                exact
                path={`${path}/:typeOfServiceProvider/add`}
                render={(props) => <AdminAddServiceProvider {...props} />}
              />
              <Route
                exact
                path={`${path}/:typeOfServiceProvider`}
                render={(props) => <AdminListServiceProvider {...props} />}
              />
              <Route
                exact
                path={`${path}/adminProfile/profile`}
                key={`${path}/adminProfile/profile`}
                render={(props) => <AdminProfile {...props} />}
              />

              <Route
                exact
                path={`${path}/adminSettings/settings`}
                render={(props) => <AdminSettings userRole={userRole} />}
              />
              <Route
                exact
                path={`${path}/searchResults`}
                render={(props) => (
                  <GlobalSearchResults searchResults={searchResults} />
                )}
              />
              <Route path="/login">
                <LoginPage onLogin={handleLogin} />
              </Route>
            </Switch> */}

            <RenderOnAnonymous>
              <Welcome />
            </RenderOnAnonymous>

            <RenderOnAuthenticated>

             
              <Routes>
                <Route
                  path='/dashboard/main-dashboard' //both paths lead to one component (path = /admin)
                  element={
                    <RolesRoute roles={['MogzitAdmin']} Component={ReportDashboard} />
                  }
                />
                <Route
                path='/dashboard/main-dashboard' //both paths lead to one component (path = /admin)
                element={
                  <RolesRoute roles={['MogzitAdmin']} Component={ReportDashboard} />
                }
              />
              <Route
                path='/bookings/new'
                element={<ManageNewBookings />}
              />
              <Route
                path='/bookings/completed'
                element={<ManageCompletedBookings />}
              />
              <Route
                path='/bookings/deleted'
                element={<ManageDeletedBookings />}
              />

              <Route
                path='/:typeOfServiceProvider/add'
                element={<AdminAddServiceProvider />}
              />
              <Route
                path='/:typeOfServiceProvider'
                element={<AdminListServiceProvider />}
              />
              <Route
                path='/adminProfile/profile'
                element={<AdminProfile />}
              />

              <Route
                path='/adminSettings/settings'
                element={<AdminSettings userRole={userRole} />}
              />
              <Route
                path='/searchResults'
                element={
                  <GlobalSearchResults searchResults={searchResults} />
                }
              />
              <Route
                path='/login'
                element={
                  <LoginPage onLogin={handleLogin} />
                }
              />
              </Routes>
            </RenderOnAuthenticated>

            {/* <RenderOnRole roles={['MogzitAdmin']}>
              <button type="submit">Add Tenant <em>(you see this button because you are an admin)</em></button>
            </RenderOnRole>

            <RenderOnRole roles={['tenant']}>
              <button type="submit">Add Gig Worker <em>(you see this button because you are a tenant)</em></button>
            </RenderOnRole> */}

            {/* <Routes>
              <Route path='/tenants/new' element={
                <RolesRoute roles={['admin']} Component={ReportDashboard} />
              } />
            </Routes> */}
          </Content>
        </Layout>
      </Layout>
      <AdminFooter />
    </>
  );
};

export default AdminDashboard;
