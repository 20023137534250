import React, { useContext } from "react";
import { FloatButton } from "antd";
import { LanguageContext } from "../Language/LanguageContext";
import logo from "../../assets/Footerlogo.png";
import { languageContent } from "../Language/languageContent";
import { VerticalAlignTopOutlined } from "@ant-design/icons";
import "../UserNavbar/Navbar.css";
const Footer = () => {
  const { language } = useContext(LanguageContext);

  const {
    home,
    about,
    services,
    contact,
    slogan,
    overView,
    info,
    headOffice,
    cookiePolicy,
    terms,
    privacyPolicy,
    building,
    address,
    city,
  } = languageContent[language];

  return (
    <>
      <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3  gap-8 text-gray-300 mb-6 flex flex-col lg:flex-row justify-center items-center space-y-6 lg:space-y-0 lg:space-x-6">
        <div className="text-center lg:text-left ">
          <div className="text-center lg:text-left  ">
            <a href="https://mogzit.com" className="logo">
              <img
                src={logo}
                alt="logo"
                id="logo-two"
                className="ml-5 lg:ml-0"
              />
            </a>
          </div>
          <p className="py-4font-bold sm:text-sm lg:text-lg md:text-lg sora text-white">
            {slogan}
          </p>
        </div>
        <div className="lg:col-span-2 flex  flex-col lg:flex-row justify-between mt-6 space-y-6 lg:space-y-0 lg:space-x-6">
          <div className="text-center">
            <h6 className="font-bold sm:text-sm lg:text-lg md:text-lg sora text-white">
              {overView}
            </h6>
            <ul className="text-xs lg:text-base sora">
              <li className="footer-item py-2 text-sm text-white">
                <a href="https://mogzit.com/">
                  <p className="">{home}</p>
                </a>
              </li>
              <li className="footer-item py-2 text-sm text-white">
                <a href="https://mogzit.com/about-care/">
                  <p className="">{about}</p>
                </a>
              </li>
              <li className="footer-item py-2 text-sm text-white">
                <a href="https://mogzit.com/nanny-service-ethiopia/">
                  <p className="">{services}</p>
                </a>
              </li>
              <li className="footer-item py-2 text-sm text-white">
                <a href="https://mogzit.com/contact/">
                  <p className="">{contact}</p>
                </a>
              </li>
            </ul>
          </div>
          <div className="text-center">
            <h6 className="font-bold sm:text-sm lg:text-lg md:text-lg sora text-white">
              {info}
            </h6>
            <ul className="text-xs lg:text-base sora">
              <li className="footer-item py-2 text-sm text-white">
                <a href="https://mogzit.com/contact/">
                  <p className="">{contact}</p>
                </a>
              </li>
              <li className="footer-item py-2 text-sm text-white">
                <a href="https://mogzit.com/cookie-policy/">
                  <p className="">{cookiePolicy}</p>
                </a>
              </li>
              <li className="footer-item py-2 text-sm text-white">
                <a href="https://mogzit.com/terms-and-conditions/">
                  <p className="">{terms}</p>
                </a>
              </li>
              <li className="footer-item py-2 text-sm text-white">
                <a href="https://mogzit.com/privacy-policy-2/">
                  <p className="">{privacyPolicy}</p>
                </a>
              </li>
            </ul>
          </div>
          <div className="text-center">
            <h6 className="font-bold sm:text-sm lg:text-lg md:text-lg sora text-white">
              {headOffice}
            </h6>
            <ul className="text-xs lg:text-base sora">
              <li className="pt-2 text-sm text-white">{building}</li>
              <li className=" text-sm text-white">{address}</li>
              <li className=" text-sm text-white">{city}</li>
              <li className=" text-sm text-white">993278 or +251952133990 </li>
              <li className="text-sm text-white">
                mogzitinhomecare.info@gmail.com
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className="max-w-[2240px] mt-[-50px] w-full h-small mx-auto text-center flex flex-col justify-center py-4"
        style={{ backgroundColor: "#CE257A" }}
      >
        <hr className="border-t border-gray-300 w-full opacity-30 lg:max-w-[1240px] mx-auto mb-8 mt-[-16px]" />
        <p className="md:text-xl text-xl font-regular text-white sora">
          Copyright © {new Date().getFullYear()} Mogzit In-Home Care
        </p>

        <FloatButton.BackTop>
          <div className="ant-back-top-inner">
            <VerticalAlignTopOutlined
              style={{ fontSize: "2.3rem", color: "white" }}
            />
          </div>
        </FloatButton.BackTop>
      </div>
    </>
  );
};

export default Footer;
