import React, { useEffect, useState } from "react";
import { Table, Empty } from "antd";
import { searchListColumns } from "./listColumns";
import { ServiceProviderAPI } from "../../../apis/serviceProviderAPI";

const GlobalSearchResults = ({ searchResults }) => {
  const [serviceProviderList, setServiceProviderList] = useState([]);

  useEffect(() => {
    console.log(`GlobalSearchResults.js`)
    if (searchResults.length > 0) {
      setServiceProviderList(searchResults);
    } else {
      ServiceProviderAPI.getAll("all")
        .then((results) => {
          setServiceProviderList(results);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [searchResults]);

  return serviceProviderList.length > 0 ? (
    <Table dataSource={serviceProviderList} columns={searchListColumns()} />
  ) : (
    <Empty description="Sorry , there is no service provider by this name." />
  );
};

export default GlobalSearchResults;
