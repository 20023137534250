import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const ServiceProviderAPI = {
  get: async function (typeOfServiceProvider, id, cancel = false) {
    const response = await api.request({
      url: `${typeOfServiceProvider}/${id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    // returning the serviceProvider returned by the API
    return response.data;
  },
  getAll: async function (
    typeOfServiceProvider,
    filterOutInactive = true,
    filterOutBooked = true,
    withRating = true,
    cancel = false
  ) {
    // console.log(`here typeOfServiceProvider: `, typeOfServiceProvider)
    let serviceProviderTypes = [];
    if (typeOfServiceProvider === "all") {
      // If the type is 'all', fetch all types of service providers
      serviceProviderTypes = ["nanny", "cookCleaner", "homeTutor"];
    } else {
      // Otherwise, fetch the specific type of service provider
      serviceProviderTypes = [typeOfServiceProvider];
    }

    const allResults = [];
    for (const type of serviceProviderTypes) {
      const response = await api.request({
        url: `/${type}?filterOutInactive=${filterOutInactive}&filterOutBooked=${filterOutBooked}&withRating=${withRating}`,
        method: "GET",
        signal: cancel
          ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
          : undefined,
      });
      allResults.push(...response.data);
    }

    return allResults;
  },

  delete: async function (
    typeOfServiceProvider,
    idToBeDeleted,
    cancel = false
  ) {
    await api.request({
      url: `${typeOfServiceProvider}/${idToBeDeleted}`,
      method: "DELETE",
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
  },
  updateFlag: async function (
    typeOfServiceProvider,
    serviceProviderId,
    updatedProperty,
    updatedFlag,
    cancel = false
  ) {
    await api.request({
      url: `/${typeOfServiceProvider}/${serviceProviderId}/${updatedProperty}?value=${updatedFlag}`,
      method: "PUT",
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
  },
  updateData: async function (
    typeOfServiceProvider,
    serviceProviderId,
    serviceProvider,
    cancel = false
  ) {
    await api.request({
      url: `/${typeOfServiceProvider}/${serviceProviderId}`,
      method: "PUT",
      data: serviceProvider,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
  },
  create: async function (
    typeOfServiceProvider,
    serviceProvider,
    cancel = false
  ) {
    const response = await api.request({
      url: `/${typeOfServiceProvider}`,
      method: "POST",
      data: serviceProvider,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  // search: async function (name, cancel = false) {
  //     const response = await api.request({
  //         url: "/serviceProviders/search",
  //         method: "GET",
  //         params: {
  //             name: name,
  //         },
  //         signal: cancel ? cancelApiObject[this.search.name].handleRequestCancellation().signal : undefined,
  //     })
  //
  //     return response.data.serviceProviders
  // },
};

// defining the cancel API object for ServiceProviderAPI
const cancelApiObject = defineCancelApiObject(ServiceProviderAPI);
