import UserService from "../../services/UserService";
import NotAllowed from "../error/NotAllowed";

const RolesRoute = ({roles, Component}) => {
  const auth = UserService.hasRole(roles)
  // console.log(`RolesRoute hasRole: `, auth)
  // console.log(`Component: `, Component)
  return auth ? <Component /> : <NotAllowed/>
}
export default RolesRoute
