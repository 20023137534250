import React from "react";
import BkgImage from "../../../assets/bkg.png";

const ThankComponent = () => {
  return (
    <div
      className="w-full py-[2rem] px-4 bg-white "
      id="thankyou-section"
      style={{
        backgroundImage: `url(${BkgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minHeight: "100vh",
      }}
    >
      <h1 className="text-[#c90254] font-bold text-4xl text-center pb-10">
        Thank You for Choosing Our Service
      </h1>
      <p className="text-gray-700 text-lg text-center">
        We appreciate your trust in us and we're excited to provide you with our
        service.
      </p>
    </div>
  );
};

export default ThankComponent;
