import React, { useState, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Navbar from "../../../components/UserNavbar/Navbar.jsx";
import "./UserDashboard.css";
import "../../../index.css";
// eslint-disable-next-line no-unused-vars
//import CareGiverServices from "../../../components/CareGiverServices/Services.jsx";
import Footer from "../../../components/UserFooter/Footer.jsx";
import Hero from "../../../components/HeroSection/Hero.jsx";

import Contact from "../../../components/NeedHelp/Contact.jsx";
import ListServiceProviders from "../ListServiceProviders/ListServiceProviders";
import DetailServiceProvider from "../DetailServiceProvider/DetailServiceProvider";
import BookServiceProvider from "../BookServiceProvider/BookServiceProvider";
import GiveRatingAndReview from "../RateAndReview/GiveRatingAndReview";
import { LanguageContext } from "../../../components/Language/LanguageContext";
import ThankComponent from "../ThankYou/ThankComponent.js";
import NoMatch from "../../../components/error/NoMatch";

function UserDashboard() {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "en"
  );
  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  return (
    <div>
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <Navbar />
        <Hero />

        <Routes>
          {/* Main booking page should redirect to nanny page as default */}

          <Route path="/" element={<Navigate to="/nanny" />} />
          {/* <Route path='/admin' element={
            <RolesRoute roles={['MogzitAdmin']} Component={AdminDashboard} />
          } /> */}
          <Route path='/:typeOfServiceProvider' element={<ListServiceProviders />}/>
          <Route
            path="/:typeOfServiceProvider/:id"
            element={<DetailServiceProvider />}
          />
          <Route
            path="/book/:typeOfServiceProvider/:id"
            element={<BookServiceProvider />}
          />
          {/* EXAMPLE http://localhost:3000/rate/Samrawit/nanny/2 */}
          <Route
            path="/rate/:serviceProviderName/:typeOfServiceProvider/:bookingId"
            element={ <GiveRatingAndReview />}
          />
          <Route path="/thankyou" element={ThankComponent} />
          <Route path='*' element={<NoMatch/>}/>
        </Routes>

        {/* <CareGiverServices /> */}
        <Contact />
        <Footer />
      </LanguageContext.Provider>
    </div>
  );
}

export default UserDashboard;
