import { Layout } from "antd";
import { useEffect, useState } from "react";

const { Footer } = Layout;

const AdminFooter = () => {
  const [currentYear, setCurrentYear] = useState();

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <Footer
      style={{
        background: "#CE257A",
        color: "white",
        fontFamily: "Sora",
        fontSize: "22px",
        width: "100%",
        textAlign: "center",
      }}
    >
      © {currentYear} - Mogzit-In Home Care
    </Footer>
  );
};

export default AdminFooter;
