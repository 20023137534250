import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const RatingAPI = {
    rate: async function (rateAndReview, cancel = false) {
        const response = await api.request({
            url: `/rating`,
            method: "POST",
            data: rateAndReview,
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    get: async function (typeOfServiceProvider, id, cancel = false) {
        const serviceProviderAllCaps = typeOfServiceProvider.toUpperCase()
        const response = await api.request({
            url: `/rating/${serviceProviderAllCaps}/${id}`,
            method: "GET",
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the booking returned by the API
        return response.data
    },
    getByBookingId: async function (id, cancel = false) {
        const response = await api.request({
            url: `/rating/booking/${id}`,
            method: "GET",
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the booking returned by the API
        return response.data
    },
    getAll: async function (cancel = false) {
        const response = await api.request({
            url: "/rating",
            method: "GET",
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    delete: async function (id, cancel = false) {
        await api.request({
            url: `/rating/${id}`,
            method: "DELETE",
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
    },
    create: async function (rateAndReview, cancel = false) {
        const response = await api.request({
            url: `/rating`,
            method: "POST",
            data: rateAndReview,
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    // search: async function (name, cancel = false) {
    //     const response = await api.request({
    //         url: "/bookings/search",
    //         method: "GET",
    //         params: {
    //             name: name,
    //         },
    //         signal: cancel ? cancelApiObject[this.search.name].handleRequestCancellation().signal : undefined,
    //     })
    //
    //     return response.data
    // },
}

// defining the cancel API object for RateAPI
const cancelApiObject = defineCancelApiObject(RatingAPI)
